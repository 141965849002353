import Http from '../../Globals/Http';

const login		= async (username, password)	=> await Http.request('admin/auth/login', {username,password}, false);
// const login = async (token)							=> await Http.request('auth/login', token);


const getUsers		= async ()						=> await Http.get('admin/user/me');
// const complex	= async (token)					=> await Http.exampleRequest(`admin/complex`, token);

const getUserById	= async (id)					=> await Http.get(`admin/user/${id}`);
const add		= async (data)					=> await Http.request('user/add', data);
const update	= async (id, data)				=> await Http.request(`user/update/${id}`, data);


export {
	login,
	getUsers,
	// complex,
	add,
	update,
	getUserById,
}