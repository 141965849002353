import React, {useEffect, useContext} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Template from "../../Components/Template";
import {get} from "../../Models/Exercise";
import {Context} from "../../Components/Provider";

// styles
import './styles.css';
import Loader from "../../Components/Loader";

// import { LRUCache } from 'lru-cache';

// const cache = new LRUCache({ max: 100 });



const ExercisesScreen = () => {

    const {exercise, setExercise, cash, setCash} = useContext(Context);

    const history = useHistory();
    // const [exercise, setExercise] = useState(null);

    useEffect(() => {
        const getData = async () => {

            // const cachedData = cache.get('exercises');
            if (cash) {
                setExercise(cash);
                return;
            }
            // если данных нет в кэше, то делаем запрос
            try {
                const result = await get();
                setExercise(result);

                // сохраняем полученные данные в кэше
                setCash(result);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    const handleClick = (item) => {
        // передаем параметры в объекте вторым аргументом
        history.push('/exercise', {
            id: item._id,
            code: item.code,
            forChildren: item.forChildren,
            gender: item.gender,
            important: item.important,
            inventory: item.inventory,
            muscles: item.muscles,
            name: item.name,
            replace: item.replace,
            replaceForSick: item.replaceForSick,
            sectionName: item.sectionName,
            startingPosition : item.startingPosition,
            technique : item.technique,
        });
    }

    const handleGoToAddExercise = (event) => {
        event.preventDefault();
        history.push('/addExercise');
    }

    return (
        <Template>
            <div className='exercises__container'>
                <div className='exercise__container__head'>
                    <h3 className='exercises__header'>Упражнения</h3>
                    <button onClick={handleGoToAddExercise} className='exercise__header right add'>Добавить</button>
                </div>
                {   exercise ?
                    <table className='exercises__table'>
                        <thead className='exercises__table__header'>
                        <tr className='exercises__table__row table-tr__th'>
                            <th>№</th>
                            <th>Код</th>
                            <th>Для детей</th>
                            <th>Пол</th>
                            <th>Инвентарь</th>
                            <th>Мышцы</th>
                            <th>Название</th>
                            <th>Замена</th>
                            <th>Замена для больных</th>
                            <th>Название секции</th>
                        </tr>
                        </thead>
                        <tbody>
                        {exercise ? exercise.map((item, index) =>
                            <tr className='exercises__table__row exercises__link table-tr__td' key={index}
                                onClick={() => handleClick(item)} >
                                <td>{index + 1}</td>
                                <td>{item.code || '-'}</td>
                                <td>{item.forChildren ? 'Да' : 'Нет'}</td>
                                <td>{item.gender || '-'}</td>
                                <td>{item.inventory || '-'}</td>
                                <td>{item.muscles || '-'}</td>
                                <td>{item.name || '-'}</td>
                                <td>{item.replace ? item.replace.join(', ') : '-'}</td>
                                <td>{item.replaceForSick ? item.replaceForSick.join(', ') : '-'}</td>
                                <td>{item.sectionName || '-'}</td>
                            </tr>
                        ) : null}
                        </tbody>
                    </table> : <Loader/>
                }
            </div>
        </Template>
    )
}

export default ExercisesScreen;