import React from 'react';
import Template from "../../Components/Template";

const ReferencesScreen = () => {
    return (
        <Template>
            <div>References</div>
        </Template>
    )
}

export default ReferencesScreen;