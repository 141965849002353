import React from 'react';
import Template from "../../Components/Template";

const ClientsScreen = () => {
    return (
        <Template>
            <div>Clients</div>
        </Template>
    )
}

export default ClientsScreen;