import Http from '../../Globals/Http';

const get		= async ()						=> await Http.get('admin/complexes');

const create	= async (data)				=> await Http.request(`admin/complexes/`, data);

const update	= async (id, data)				=> await Http.put(`admin/complexes/${id}`, data);

export {
    get,
    update,
    create,
}