import React, {useContext, useEffect, useState} from 'react';
import { useLocation, useHistory } from "react-router-dom";

// styles
import './styles.css';

import Template from "../../Components/Template";
import Loader from "../../Components/Loader";
import {update, get} from "../../Models/Training";

import {Context} from "../../Components/Provider";

const TrainScreen = () => {

    const imgUrl = 'https://s3.super-appz.ru/download/fitness/images/exercises';
    const videoUrl = 'https://s3.super-appz.ru/download/fitness/video/exercises';
    const location = useLocation();
    const history = useHistory();

    const {setTraining, setTrainingCash} = useContext(Context);

    const [formData, setFormData] = useState({
        id: location.state.id,
        code: location.state.code,
        countCircles: location.state.countCircles,
        countExercise: location.state.countExercise,
        exercises: location.state.exercises,
        level: location.state.level,
        gender: location.state.gender,
        hitch: location.state.hitch,
        name: location.state.name,
        place: location.state.place,
        sectionName : location.state.sectionName,
        target: location.state.target,
        task: location.state.task,
        timeToRelax: location.state.timeToRelax,
        timeToTraining: location.state.timeToTraining,
        type: location.state.type,
        warmUp: location.state.warmUp,
        warmUpJoint: location.state.warmUpJoint,
    });

    console.log(location.state);

    const handleWarmUpChange = (index, field, value) => {
        const updatedWarmUp = [...formData.warmUp];
        updatedWarmUp[index][field] = value;
        setFormData({ ...formData, warmUp: updatedWarmUp });
    }

    const handleWarmUpJointChange = (index, field, value) => {
        const updatedWarmUpJoint = [...formData.warmUpJoint];
        updatedWarmUpJoint[index][field] = value;
        setFormData({ ...formData, warmUpJoint: updatedWarmUpJoint });
    }

    const handleHitchChange = (index, field, value) => {
        const updatedHitch = [...formData.hitch];
        updatedHitch[index][field] = value;
        setFormData({ ...formData, hitch: updatedHitch });
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleTrainExerciseChange = (event, index) => {
        const { name, value } = event.target;
        const field = name.split('.');
        const fieldName = field[1]; // получаем имя поля (code)
        const exercises = [...formData.exercises];
        const currentExercise = exercises[index];
        const currentField = field[2];

        currentExercise[fieldName][currentField] = value;

        setFormData({
            ...formData,
            exercises: exercises,
        });
    };

    const handleTrainSuperSetChange = (field, value, exerciseIndex, superSetIndex) => {
        // Обновляем значение в formData
        const updatedFormData = { ...formData };
        updatedFormData.exercises[exerciseIndex].superSet[superSetIndex].exercise[field] = value;
        updatedFormData.exercises[exerciseIndex].superSet[superSetIndex].options[field] = value;
        setFormData(updatedFormData);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const updateTrain = {
            "code": formData.code,
            "countCircles" : formData.countCircles,
            "countExercise" : formData.countExercise,
            "level" : formData.level,
            "place" : formData.place,
            "target" : formData.target,
            "task" : formData.task,
            "timeToRelax" : formData.timeToRelax,
            "timeToTraining" : formData.timeToTraining,
            "type" : formData.type,
            "gender" : formData.gender,
            "name": formData.name,
            "sectionName": formData.sectionName,
            "warmUp" : formData.warmUp,
            "warmUpJoint" : formData.warmUpJoint,
            "hitch" : formData.hitch,
            "exercises" : formData.exercises,
        };
        // const url = 'http://example.com/api/endpoint';
        //
        // fetch(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(updateTrain)
        // })
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error('Network response was not ok');
        //         }
        //         return response.json();
        //     })
        //     .then(data => {
        //         console.log(data); // Обработка успешного ответа
        //     })
        //     .catch(error => {
        //         console.error('Error:', error); // Обработка ошибок
        //     });
        await update(location.state.id, updateTrain);
        const result = await get();

        setTraining(result);
        setTrainingCash(result);
    };

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/training');
    }

    const handleGoToAddTrain = (event) => {
        event.preventDefault();
        history.push('/addTrain');
    }

    function handleClick() {
        alert('Сохранено');
    }

    return (
        <Template>
            <div>
                <div className='train__container__head'>
                    <div className='head__edit'>
                        <button onClick={handleGoBack} className='back-button'></button>
                        <h4 className='train__header left'>Редактирование тренировки</h4>
                    </div>
                </div>
                <form className='train__form-container'
                      onSubmit={handleSubmit}
                >
                    <div className='train__form-container__grid'>
                        <div className='train__common__container'>
                            <label className='train__common__one'>
                                <span className='train__form-container__name'>Название</span>
                                <input className='train__form-container__input' type="text" name="name" value={formData.name} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__one'>
                                <span className='train__form-container__name'>Код</span>
                                <input className='train__form-container__input' type="text" name="code" value={formData.code} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__one'>
                                <span className='train__form-container__name'>Кол-во циклов</span>
                                <input className='train__form-container__input' type="text" name="countCircles" value={formData.countCircles} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__one'>
                                <span className='train__form-container__name'>Кол-во упражнений</span>
                                <input className='train__form-container__input' type="text" name="countExercise" value={formData.countExercise} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__two'>
                                <span className='train__form-container__name'>Пол</span>
                                <input className='train__form-container__input' type="text" name="gender" value={formData.gender} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__two'>
                                <span className='train__form-container__name'>Уровень тренировки</span>
                                <input className='train__form-container__input' type="text" name="level" value={formData.level} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__two'>
                                <span className='train__form-container__name'>Место</span>
                                <input className='train__form-container__input' type="text" name="place" value={formData.place} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__two'>
                                <span className='train__form-container__name'>Название секции</span>
                                <input className='train__form-container__input' type="text" name="sectionName" value={formData.sectionName} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__three'>
                                <span className='train__form-container__name'>Цель тренировки</span>
                                <input className='train__form-container__input' type="text" name="target" value={formData.target} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__three'>
                                <span className='train__form-container__name'>Время отдыха</span>
                                <input className='train__form-container__input' type="text" name="timeToRelax" value={formData.timeToRelax} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__three'>
                                <span className='train__form-container__name'>Время тренировки</span>
                                <input className='train__form-container__input' type="text" name="timeToTraining" value={formData.timeToTraining} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__three'>
                                <span className='train__form-container__name'>Тип тренировки</span>
                                <input className='train__form-container__input' type="text" name="type" value={formData.type} onChange={handleInputChange} />
                            </label>
                            <label className='train__common__four'>
                                <span className='train__form-container__name'>Задача</span>
                                <textarea className='train__form-container__input' type="text" name="task" value={formData.task} onChange={handleInputChange} />
                            </label>
                        </div>
                        <label className='train__warm-up__container'>
                            <span className='train__form-container__name-header'>Разминки</span>
                            {
                                formData.warmUp.map((item, index) =>
                                    <div key={index}>
                                        <div className='complex__form-container__name-header circle'>{`Разминка № ${index + 1}`}</div>
                                        <div className='warm__container'>
                                            <label className='warm__one'>
                                                <span className='complex__form-container__name'>Название разминки</span>
                                                <input className='complex__form-container__input-same' type="text" name="warmUp.name" value={item.name} onChange={event => handleWarmUpChange(index, 'name', event.target.value)} />
                                            </label>
                                            <label className='warm__one'>
                                                <span className='complex__form-container__name'>Код</span>
                                                <input className='complex__form-container__input-same' type="text" name="warmUp.code" value={item.code} onChange={event => handleWarmUpChange(index, 'code', event.target.value)} />
                                            </label>
                                            <label className='warm__one'>
                                                <span className='complex__form-container__name'>Пол</span>
                                                <input className='complex__form-container__input-same' type="text" name="warmUp.gender" value={item.gender} onChange={event => handleWarmUpChange(index, 'gender', event.target.value)} />
                                            </label>
                                            <label className='warm__two'>
                                                <span className='complex__form-container__name '>Важно</span>
                                                <textarea className='complex__form-container__input-same' type="text" name="warmUp.important" value={item.important} onChange={event => handleWarmUpChange(index, 'important', event.target.value)} />
                                            </label>
                                            <label className='warm__two'>
                                                <span className='complex__form-container__name'>Инвентарь</span>
                                                <textarea className='complex__form-container__input-same' type="text" name="warmUp.inventory" value={item.inventory} onChange={event => handleWarmUpChange(index, 'inventory', event.target.value)} />
                                            </label>
                                            <label className='warm__two'>
                                                <span className='complex__form-container__name'>Мышцы</span>
                                                <textarea className='complex__form-container__input-same' type="text" name="warmUp.muscles" value={item.muscles} onChange={event => handleWarmUpChange(index, 'muscles', event.target.value)} />
                                            </label>
                                            <label className='warm__three'>
                                                <span className='complex__form-container__name'>Название секции</span>
                                                <textarea className='complex__form-container__input-same' type="text" name="warmUp.sectionName" value={item.sectionName} onChange={event => handleWarmUpChange(index, 'muscles', event.target.value)} />
                                            </label>
                                        </div>
                                        <div className='warm__video'>
                                            <div className='complex__form-container__name-header circle'>{`Видео разминки № ${index + 1}`}</div>
                                            <video src={`${videoUrl}/${item.code}/${item.code}.mp4`} controls width="640" height="360">
                                                Ваш браузер не поддерживает воспроизведение видео.
                                            </video>
                                        </div>
                                    </div>
                                )}
                        </label>
                        <label>
                            <span className='train__form-container__name-header'>Разминки сустава</span>
                            {
                                formData.warmUpJoint.map((item, index) =>
                                    <div key={index}>
                                        <div className='complex__form-container__name-header circle'>{`Разминка № ${index + 1}`}</div>
                                        <div className='warm__container'>
                                            <label className='warm__one'>
                                                <span className='complex__form-container__name'>Название разминки</span>
                                                <input className='complex__form-container__input-same' type="text" name="warmUpJoint.name" value={item.name} onChange={event => handleWarmUpJointChange(index, 'name', event.target.value)} />
                                            </label>
                                            <label className='warm__one'>
                                                <span className='complex__form-container__name'>Код</span>
                                                <input className='complex__form-container__input-same' type="text" name="warmUpJoint.code" value={item.code} onChange={event => handleWarmUpJointChange(index, 'code', event.target.value)} />
                                            </label>
                                            <label className='warm__one'>
                                                <span className='complex__form-container__name'>Мышцы</span>
                                                <input className='complex__form-container__input-same' type="text" name="warmUpJoint.muscles" value={item.muscles} onChange={event => handleWarmUpJointChange(index, 'gender', event.target.value)} />
                                            </label>
                                            <label className='warm__two'>
                                                <span className='complex__form-container__name'>Пол</span>
                                                <input className='complex__form-container__input-same' type="text" name="warmUpJoint.gender" value={item.gender} onChange={event => handleWarmUpJointChange(index, 'gender', event.target.value)} />
                                            </label>
                                            <label className='warm__two'>
                                                <span className='complex__form-container__name'>Название секции</span>
                                                <textarea className='complex__form-container__input-same' type="text" name="warmUpJoint.sectionName" value={item.sectionName} onChange={event => handleWarmUpJointChange(index, 'muscles', event.target.value)} />
                                            </label>
                                        </div>
                                        <div className='warm__video'>
                                            <div className='complex__form-container__name-header circle'>{`Видео разминки № ${index + 1}`}</div>
                                            <video src={`${videoUrl}/${item.code}/${item.code}.mp4`} controls width="640" height="360">
                                                Ваш браузер не поддерживает воспроизведение видео.
                                            </video>
                                        </div>
                                    </div>
                                )}
                        </label>
                        <label>
                            <span className='train__form-container__name-header'>Заминки</span>
                            {
                                formData.hitch.map((item, index) =>
                                    <div key={index}>
                                        <div className='complex__form-container__name-header circle'>{`Заминка № ${index + 1}`}</div>
                                        <div className='warm__container'>
                                            <label className='warm__one'>
                                                <span className='complex__form-container__name'>Название заминки</span>
                                                <input className='complex__form-container__input-same' type="text" name="hitch.name" value={item.name} onChange={event => handleHitchChange(index, 'name', event.target.value)} />
                                            </label>
                                            <label className='warm__one'>
                                                <span className='complex__form-container__name'>Код</span>
                                                <input className='complex__form-container__input-same' type="text" name="hitch.code" value={item.code} onChange={event => handleHitchChange(index, 'code', event.target.value)} />
                                            </label>
                                            <label className='warm__one'>
                                                <span className='complex__form-container__name'>Мышцы</span>
                                                <input className='complex__form-container__input-same' type="text" name="hitch.muscles" value={item.muscles} onChange={event => handleHitchChange(index, 'gender', event.target.value)} />
                                            </label>
                                            <label className='warm__two'>
                                                <span className='complex__form-container__name'>Пол</span>
                                                <input className='complex__form-container__input-same' type="text" name="hitch.gender" value={item.gender} onChange={event => handleHitchChange(index, 'gender', event.target.value)} />
                                            </label>
                                            <label className='warm__two'>
                                                <span className='complex__form-container__name'>Название секции</span>
                                                <input className='complex__form-container__input-same' type="text" name="hitch.sectionName" value={item.sectionName} onChange={event => handleHitchChange(index, 'muscles', event.target.value)} />
                                            </label>
                                            <label className='warm__two'>
                                                <span className='complex__form-container__name '>Важно</span>
                                                <textarea className='complex__form-container__input-same' type="text" name="hitch.important" value={item.important} onChange={event => handleHitchChange(index, 'important', event.target.value)} />
                                            </label>
                                            <label className='warm__three'>
                                                <span className='complex__form-container__name'>Инвентарь</span>
                                                <textarea className='complex__form-container__input-same' type="text" name="hitch.inventory" value={item.inventory} onChange={event => handleHitchChange(index, 'inventory', event.target.value)} />
                                            </label>
                                        </div>
                                        <div className='warm__video'>
                                            <div className='complex__form-container__name-header circle'>{`Видео заминки № ${index + 1}`}</div>
                                            <video src={`${videoUrl}/${item.code}/${item.code}.mp4`} controls width="640" height="360">
                                                Ваш браузер не поддерживает воспроизведение видео.
                                            </video>
                                        </div>
                                    </div>
                                )}
                        </label>
                        <label>
                            <span className='train__form-container__name-header'>Упражнения</span>
                            {formData.exercises.map((item, exerciseIndex) =>
                                <div>
                                    <div className='train__form-container__name-header-two'>{`Упражнение № ${exerciseIndex + 1}`}</div>
                                    {
                                        item.hasOwnProperty('superSet') ? <div>
                                            { item.superSet.map((superSet, superSetIndex) =>
                                                <div>
                                                    <div className='train__form-container__name-header-set'>{`Супер сет № ${superSetIndex + 1}`}</div>
                                                    <div className='train__set__container'>
                                                        <label key={superSetIndex} className='set__one'>
                                                            <span className='exercise__form-container__name'>Код</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.code`} value={superSet.exercise.code} onChange={(event) => handleTrainSuperSetChange('code', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__one'>
                                                            <span className='exercise__form-container__name'>Пол</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.gender`} value={superSet.exercise.gender} onChange={(event) => handleTrainSuperSetChange('gender', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        {/*<label className='set__one'>*/}
                                                        {/*    <span className='exercise__form-container__name'>Важно</span>*/}
                                                        {/*    <textarea className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.important`} value={superSet.exercise.important} onChange={(event) => handleTrainSuperSetChange('important', event.target.value, exerciseIndex, superSetIndex)}  />*/}
                                                        {/*</label>*/}
                                                        <label className='set__one'>
                                                            <span className='exercise__form-container__name'>Техника</span>
                                                            <textarea className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.technique`} value={superSet.exercise.technique} onChange={(event) => handleTrainSuperSetChange('technique', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__two'>
                                                            <span className='exercise__form-container__name'>Инвентарь</span>
                                                            <textarea className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.inventory`} value={superSet.exercise.inventory} onChange={(event) => handleTrainSuperSetChange('inventory', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__two'>
                                                            <span className='exercise__form-container__name'>Мышцы</span>
                                                            <textarea className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.muscles`} value={superSet.exercise.muscles} onChange={(event) => handleTrainSuperSetChange('muscles', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__two'>
                                                            <span className='exercise__form-container__name'>Название</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.name`} value={superSet.exercise.name} onChange={(event) => handleTrainSuperSetChange('name', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__three'>
                                                            <span className='exercise__form-container__name'>Похожее упражнение</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.replace`} value={superSet.exercise.replace} onChange={(event) => handleTrainSuperSetChange('replace', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__three'>
                                                            <span className='exercise__form-container__name'>Похожее упражнение при болезни</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.replaceForSick`} value={superSet.exercise.replaceForSick} onChange={(event) => handleTrainSuperSetChange('replaceForSick', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__three'>
                                                            <span className='exercise__form-container__name'>Название секции</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.exercise.sectionName`} value={superSet.exercise.sectionName} onChange={(event) => handleTrainSuperSetChange('sectionName', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__four'>
                                                            <span className='exercise__form-container__name'>Количество повторов</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.options.countRepeat`}  value={superSet.options.countRepeat} onChange={(event) => handleTrainSuperSetChange('countRepeat', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__four'>
                                                            <span className='exercise__form-container__name'>Количество раз</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.options.countTimes`}  value={superSet.options.countTimes} onChange={(event) => handleTrainSuperSetChange('countTimes', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__four'>
                                                            <span className='exercise__form-container__name'>Время отдыха</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.options.timeRelax`}  value={superSet.options.timeRelax} onChange={(event) => handleTrainSuperSetChange('timeRelax', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                        <label className='set__five'>
                                                            <span className='exercise__form-container__name'>Время действия</span>
                                                            <input className='exercise__form-container__input' type="text" name={`superSet.${superSetIndex}.options.timeToDo`}  value={superSet.options.timeToDo} onChange={(event) => handleTrainSuperSetChange('timeToDo', event.target.value, exerciseIndex, superSetIndex)}  />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <div className='train__form-container__name-header-set'>{`Фото супер сета № ${superSetIndex + 1}`}</div>
                                                        <div className='complex__circle_img gap'>
                                                            <img className='complexes__form-container__img__item circle__img__one' src={`${imgUrl}/${superSet.exercise.code}/${superSet.exercise.code}-1.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__one' src={`${imgUrl}/${superSet.exercise.code}/${superSet.exercise.code}-2.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__two' src={`${imgUrl}/${superSet.exercise.code}/${superSet.exercise.code}-3.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__two' src={`${imgUrl}/${superSet.exercise.code}/${superSet.exercise.code}-4.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__three' src={`${imgUrl}/${superSet.exercise.code}/${superSet.exercise.code}-5.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__three' src={`${imgUrl}/${superSet.exercise.code}/${superSet.exercise.code}-6.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className='warm__video'>
                                                        <div className='train__form-container__name-header-set-video'>{`Видео супер сета № ${superSetIndex + 1}`}</div>
                                                        <video src={`${videoUrl}/${superSet.exercise.code}/${superSet.exercise.code}.mp4`} controls width="640" height="360">
                                                            Ваш браузер не поддерживает воспроизведение видео.
                                                        </video>
                                                    </div>
                                                </div>
                                            )}
                                        </div> : (
                                            <div>
                                                <div className='train__exercise__container'>
                                                    <label key={exerciseIndex} className='exercise__one'>
                                                        <span className='exercise__form-container__name'>Код</span>
                                                        <input className='exercise__form-container__input' type="text" name="exercises.exercise.code" value={item.exercise.code} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label className='train__exercise__one'>
                                                        <span className='exercise__form-container__name'>Пол</span>
                                                        <input className='exercise__form-container__input' type="text" name="exercises.exercise.gender" value={item.exercise.gender} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    {/*<label className='train__exercise__one'>*/}
                                                    {/*    <span className='exercise__form-container__name'>Важно</span>*/}
                                                    {/*    <textarea className='exercise__form-container__input' type="text" name="exercises.exercise.important" value={item.exercise.important} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />*/}
                                                    {/*</label>*/}
                                                    <label className='train__exercise__one'>
                                                        <span className='exercise__form-container__name'>Техника</span>
                                                        <textarea className='exercise__form-container__input' type="text" name="exercises.exercise.technique" value={item.exercise.technique} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label className='train__exercise__two'>
                                                        <span className='exercise__form-container__name'>Инвентарь</span>
                                                        <input className='exercise__form-container__input' type="text" name="exercises.exercise.inventory" value={item.exercise.inventory} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label>
                                                        <span className='exercise__form-container__name'>Мышцы</span>
                                                        <textarea className='exercise__form-container__input' type="text" name="exercises.exercise.muscles" value={item.exercise.muscles} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label>
                                                        <span className='exercise__form-container__name'>Название</span>
                                                        <textarea className='exercise__form-container__input' type="text" name="exercises.exercise.name" value={item.exercise.name} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label>
                                                        <span className='exercise__form-container__name'>Похожее упражнение</span>
                                                        <input className='exercise__form-container__input' type="text" name="exercises.exercise.replace" value={item.exercise.replace} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label>
                                                        <span className='exercise__form-container__name'>Похожее упражнение при болезни</span>
                                                        <input className='exercise__form-container__input' type="text" name="exercises.exercise.replaceForSick" value={item.exercise.replaceForSick} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label>
                                                        <span className='exercise__form-container__name'>Название секции</span>
                                                        <textarea className='exercise__form-container__input' type="text" name="exercises.exercise.sectionName" value={item.exercise.sectionName} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label>
                                                        <span className='exercise__form-container__name'>Количество повторов</span>
                                                        <textarea className='exercise__form-container__input' type="text" name="exercises.options.countRepeat" value={item.options.countRepeat} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label>
                                                        <span className='exercise__form-container__name'>Количество раз</span>
                                                        <textarea className='exercise__form-container__input' type="text" name="exercises.options.countTimes" value={item.options.countTimes} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label>
                                                        <span className='exercise__form-container__name'>Время отдыха</span>
                                                        <textarea className='exercise__form-container__input' type="text" name="exercises.options.timeRelax" value={item.options.timeRelax} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                    <label>
                                                        <span className='exercise__form-container__name'>Время действия</span>
                                                        <textarea className='exercise__form-container__input' type="text" name="exercises.options.timeToDo" value={item.options.timeToDo} onChange={(event) => handleTrainExerciseChange(event, exerciseIndex)} />
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className='train__form-container__name-header-set'>{`Фото упражнения № ${exerciseIndex + 1}`}</div>
                                                    <div className='complex__circle_img gap'>
                                                        <img className='complexes__form-container__img__item circle__img__one' src={`${imgUrl}/${item.exercise.code}/${item.exercise.code}-1.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                        <img className='complexes__form-container__img__item circle__img__one' src={`${imgUrl}/${item.exercise.code}/${item.exercise.code}-2.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                        <img className='complexes__form-container__img__item circle__img__two' src={`${imgUrl}/${item.exercise.code}/${item.exercise.code}-3.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                        <img className='complexes__form-container__img__item circle__img__two' src={`${imgUrl}/${item.exercise.code}/${item.exercise.code}-4.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                        <img className='complexes__form-container__img__item circle__img__three' src={`${imgUrl}/${item.exercise.code}/${item.exercise.code}-5.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                        <img className='complexes__form-container__img__item circle__img__three' src={`${imgUrl}/${item.exercise.code}/${item.exercise.code}-6.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                    </div>
                                                </div>
                                                <div className='warm__video'>
                                                    <div className='train__form-container__name-header-set-video'>{`Видео упражнения № ${exerciseIndex + 1}`}</div>
                                                    <video src={`${videoUrl}/${item.exercise.code}/${item.exercise.code}.mp4`} controls width="640" height="360">
                                                        Ваш браузер не поддерживает воспроизведение видео.
                                                    </video>
                                                </div>
                                            </div>)
                                    }
                                </div>
                            )}
                        </label>
                    </div>
                    <div className='complex__form-container__button-container'>
                        <button onClick={handleClick} className='complex__form-container__button ' type="submit">Сохранить</button>
                    </div>
                </form>
            </div>
        </Template>
    );
};

export default TrainScreen;

