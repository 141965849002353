import {useHistory} from "react-router-dom";
import React, {useContext, useState} from "react";

import {create, get} from "../../Models/Complex";

import Template from "../../Components/Template";
import {Context} from "../../Components/Provider";

const AddComplexScreen = () => {

    const {setComplexes, setComplexesCash} = useContext(Context);
    const [circlesLength, setCirclesLength] = useState('');
    const [complexWarmUpLength, setComplexWarmUpLength] = useState('');
    const [exercisesLength, setExercisesLength] = useState('');

    const history = useHistory();

    const handleCirclesLengthChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setCirclesLength(Number(value));
        }
    };

    const handleExercisesLengthChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setExercisesLength(Number(value));
        }
    };

    const handleComplexWarmUpLengthChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setComplexWarmUpLength(Number(value));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const values = Object.fromEntries(formData.entries());

        const complexWarmUp = Array(complexWarmUpLength).fill(null).map((_, index) => ({
            name: formData.get(`complexWarmUpName[${index}]`),
            code: formData.get(`complexWarmUpCode[${index}]`),
            gender: formData.get(`complexWarmUpGender[${index}]`),
            important: formData.get(`complexWarmUpImportant[${index}]`),
            inventory: formData.get(`complexWarmUpInventory[${index}]`),
            technique: formData.get(`complexWarmUpTechnique[${index}]`),
            startingPosition : formData.get(`complexWarmUpStartingPosition[${index}]`),
            muscles : formData.get(`complexWarmUpMuscles[${index}]`),
        }));

        const circles = Array(circlesLength).fill(null).map((_, circleIndex) => ({
            exercises: Array(exercisesLength).fill(null).map((_, exerciseIndex) => ({
                exercise: {
                    name : formData.get(`circlesName[${exerciseIndex}]`),
                    code : formData.get(`circlesCode[${exerciseIndex}]`),
                    gender : formData.get(`circlesGender[${exerciseIndex}]`),
                    technique : formData.get(`circlesTechnique[${exerciseIndex}]`),
                    startingPosition : formData.get(`circlesStartingPosition[${exerciseIndex}]`),
                    muscles : formData.get(`circlesMuscles[${exerciseIndex}]`),
                    inventory : formData.get(`circlesInventory[${exerciseIndex}]`),
                    important : formData.get(`circlesImportant[${exerciseIndex}]`),
                },
                options: {
                    countTimes : formData.get(`circlesCountTimes[${exerciseIndex}]`),
                    timeRelax : formData.get(`circlesTimeRelax[${exerciseIndex}]`),
                    timeToDo : formData.get(`circlesTimeToDo[${exerciseIndex}]`),
                }
            })),
        }));

        values.circles = circles;
        values.warmUp = complexWarmUp;

        const newComplex = {
            "circles" : values.circles,
            "code" : values.code,
            "countCircles" : values.countCircles,
            "countExercise" : values.countExercise,
            "name" : values.name,
            "task" : values.task,
            "timeToRelax" : values.timeToRelax,
            "timeToTraining" : values.timeToTraining,
            "type" : values.type,
            "warmUp" : values.warmUp,
        };

        // const url = 'http://example.com/api/endpoint';
        // fetch(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(newComplex)
        // })
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error('Network response was not ok');
        //         }
        //         return response.json();
        //     })
        //     .then(data => {
        //         console.log(data); // Обработка успешного ответа
        //     })
        //     .catch(error => {
        //         console.error('Error:', error); // Обработка ошибок
        //     });
        await create(newComplex);
        const result = await get();

        setComplexes(result);
        setComplexesCash(result);
    };

    const renderComplexWarmUp = () => {
        if (complexWarmUpLength > 0 && complexWarmUpLength < 20) {
            const complexWarmUp = Array(complexWarmUpLength).fill(null);
            return complexWarmUp.map((item, index) =>
                <div key={index}>
                    <div className='complex__form-container__name-header circle'>{`Разминка № ${index + 1}`}</div>
                    <div className='warm__container'>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Название разминки</span>
                            <input className='complex__form-container__input-same' type="text"  name={`complexWarmUpName[${index}]`}/>
                        </label>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Код</span>
                            <input className='complex__form-container__input-same' type="text"  name={`complexWarmUpCode[${index}]`}/>
                        </label>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Пол</span>
                            <input className='complex__form-container__input-same' type="text"  name={`complexWarmUpGender[${index}]`}/>
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name '>Важно</span>
                            <textarea className='complex__form-container__input-same' type="text"  name={`complexWarmUpImportant[${index}]`}/>
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name'>Инвентарь</span>
                            <textarea className='complex__form-container__input-same' type="text"  name={`complexWarmUpInventory[${index}]`}/>
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name'>Техника</span>
                            <textarea className='complex__form-container__input-same' type="text"  name={`complexWarmUpTechnique[${index}]`}/>
                        </label>
                        <label className='warm__three'>
                            <span className='complex__form-container__name'>Исходная позиция</span>
                            <textarea className='complex__form-container__input-same' type="text"  name={`complexWarmUpStartingPosition[${index}]`}/>
                        </label>
                        <label className='warm__three'>
                            <span className='complex__form-container__name'>Мышцы</span>
                            <textarea className='complex__form-container__input-same' type="text"  name={`complexWarmUpMuscles[${index}]`}/>
                        </label>
                    </div>
                </div>);
        } else {
            return null;
        }
    };

    const renderCircles = () => {
        if (circlesLength > 0 && circlesLength < 20) {
            const firstCircle = (
                <div key={0}>
                    <div>
                        {Array(exercisesLength).fill(null).map((exercise, exerciseIndex) => (
                            <div key={exerciseIndex}>
                                <div className="complex__form-container__name-header circle">{`Упражнение № ${exerciseIndex + 1}`}</div>
                                <div className='circle__container'>
                                    <label className='circle__one'>
                                        <span className='complex__form-container__name'>Название упражения</span>
                                        <input
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesName[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__one'>
                                        <span className='complex__form-container__name'>Код</span>
                                        <input
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesCode[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__one'>
                                        <span className='complex__form-container__name'>Пол</span>
                                        <input
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesGender[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__two'>
                                        <span className='complex__form-container__name'>Важно</span>
                                        <textarea
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesImportant[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__two'>
                                        <span className='complex__form-container__name'>Инвентарь</span>
                                        <textarea
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesInventory[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__two'>
                                        <span className='complex__form-container__name'>Мышцы</span>
                                        <textarea
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesMuscles[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__three'>
                                        <span className='complex__form-container__name'>Исходная позиция</span>
                                        <textarea
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesStartingPosition[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__three'>
                                        <span className='complex__form-container__name'>Техника</span>
                                        <textarea
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesTechnique[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__three'>
                                        <span className='complex__form-container__name'>Время на одно упражнение</span>
                                        <input
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesCountTimes[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__four'>
                                        <span className='complex__form-container__name'>Время отдыха на одно упражнение</span>
                                        <input
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesTimeRelax[${exerciseIndex}]`}
                                        />
                                    </label>
                                    <label className='circle__four'>
                                        <span className='complex__form-container__name'>Время работы на одно упражнение</span>
                                        <input
                                            className="complex__form-container__input-same"
                                            type="text"
                                            name={`circlesTimeToDo[${exerciseIndex}]`}
                                        />
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );

            // Сохраняем первый круг в массив circles
            const circles = Array(circlesLength).fill(firstCircle);

            // Возвращаем только первый круг
            return firstCircle;
        } else {
            return null;
        }
    };

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/complexes');
    }

    function handleClick() {
        alert('Комплекс добавлен');
    }

    return (
        <Template>
            <div className='exercise__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='exercise__header left'>Создание комплекса</h4>
                </div>
            </div>
            <form className='exercise__form-container'
                  onSubmit={handleSubmit}
            >
                <div className='complex__form-container__grid'>
                    <label>
                        <span className='complex__form-container__name'>Название</span>
                        <input className='complex__form-container__input' type="text" name="name" />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Код</span>
                        <input className='complex__form-container__input' type="text" name="code" />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Кол-во циклов</span>
                        <input className='complex__form-container__input' type="text" name="countCircles" value={circlesLength} onChange={handleCirclesLengthChange}/>
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Кол-во упражнений</span>
                        <input className='complex__form-container__input' type="text" name="countExercise" value={exercisesLength} onChange={handleExercisesLengthChange}/>
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Задача</span>
                        <textarea className='complex__form-container__input' type="text" name="task" />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Время отдыха</span>
                        <input className='complex__form-container__input' type="text" name="timeToRelax" />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Время тренировки</span>
                        <input className='complex__form-container__input' type="text" name="timeToTraining" />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Тип тренировки</span>
                        <input className='complex__form-container__input' type="text" name="type" />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Кол-во разминок</span>
                        <input className='complex__form-container__input' type="text" value={complexWarmUpLength} onChange={handleComplexWarmUpLengthChange}/>
                    </label>
                    <div className='train__warm-up__container'>
                        {   complexWarmUpLength > 0 && complexWarmUpLength < 20 &&
                            <span className='train__form-container__name-header'>Разминки</span>
                        }
                        {renderComplexWarmUp()}
                    </div>
                    <div>
                        {
                            circlesLength > 0 && circlesLength < 20 &&
                            <span className='train__form-container__name-header'>Циклы ({circlesLength})</span>
                        }
                        {renderCircles()}
                    </div>
                </div>
                <div className='exercise__form-container__button-container'>
                    <button onClick={handleClick} className='exercise__form-container__button ' type="submit">Сохранить</button>
                </div>
            </form>
        </Template>
    );
};

export default AddComplexScreen;