import React, {useEffect,useState} from 'react';
import { Link } from 'react-router-dom';

// plug-ins
import { AreaChart, XAxis, Area } from 'recharts';
import Moment from 'moment';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// styles
import './styles.css';

// icons
// const icons = {
// 	down: <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 10.7071C10.0976 10.3166 10.0976 9.68342 9.70711 9.29289C9.31658 8.90237 8.68342 8.90237 8.29289 9.29289L7 10.5858L7 3C7 2.44771 6.55228 2 6 2C5.44772 2 5 2.44771 5 3L5 10.5858L3.70711 9.29289C3.31658 8.90237 2.68342 8.90237 2.29289 9.29289C1.90237 9.68342 1.90237 10.3166 2.29289 10.7071L5.29289 13.7071C5.68342 14.0976 6.31658 14.0976 6.70711 13.7071L9.70711 10.7071Z" fill="currentColor"></path></svg>,
// 	up:<svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.29289 5.29289C1.90237 5.68342 1.90237 6.31658 2.29289 6.70711C2.68342 7.09763 3.31658 7.09763 3.70711 6.70711L5 5.41421V13C5 13.5523 5.44772 14 6 14C6.55228 14 7 13.5523 7 13V5.41421L8.29289 6.70711C8.68342 7.09763 9.31658 7.09763 9.70711 6.70711C10.0976 6.31658 10.0976 5.68342 9.70711 5.29289L6.70711 2.29289C6.31658 1.90237 5.68342 1.90237 5.29289 2.29289L2.29289 5.29289Z" fill="currentColor"></path></svg>
// }

const DashboardScreen = () => {
	const [loading, setLoading] = useState(true);

	// const authToken = localStorage.getItem('authToken');
	//
	// fetch('/dashboard', {
	// 	headers: {
	// 		'Authorization': `${authToken}`
	// 	}
	// });

	useEffect(async () => {
		setLoading(false);
	}, []);
	return (
		<Template>
			{loading ? <Loader /> :
				<div className="dashboard-container">
					<h2>Дашборд</h2>
				</div>
			}
		</Template>
	);
};

export default DashboardScreen;