import React, {useState, useEffect, useContext} from 'react';
import Template from "../../Components/Template";
import './styles.css';

import {get} from "../../Models/Complex";
import Loader from "../../Components/Loader";
import {useHistory} from "react-router-dom";
import {Context} from "../../Components/Provider";

const ComplexesScreen = () => {

    const history = useHistory();
    const {complexes, setComplexes, complexesCash, setComplexesCash} = useContext(Context);

    useEffect(() => {
        const fetchData = async () => {
            // сначала проверяем, есть ли данные в кэше
            if (complexesCash) {
                setComplexes(complexesCash);
                return;
            }

            // если данных нет в кэше, то делаем запрос
            try {
                const result = await get();
                setComplexes(result);
                setComplexesCash(result);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const handleClick = (item) => {
        // передаем параметры в объекте вторым аргументом
        history.push('/complex', {
            id: item._id,
            circles: item.circles,
            code: item.code,
            countCircles: item.countCircles,
            countExercise: item.countExercise,
            name: item.name,
            task: item.task,
            timeToRelax: item.timeToRelax,
            timeToTraining: item.timeToTraining,
            type: item.type,
            warmUp: item.warmUp
        });
    }

    const handleGoToAddComplex = (event) => {
        event.preventDefault();
        history.push('/addComplex');
    }


    return (
        <Template>
            <div className='complexes__container'>
                <div className='complex__container__head'>
                    <h3 className='complexes__header'>Комплексы</h3>
                    <button onClick={handleGoToAddComplex} className='exercise__header right add'>Добавить</button>
                </div>
                {   complexes ?
                    <table className='complexes__table'>
                        <thead className='complexes__table__header'>
                        <tr className='complexes__table__row table-tr__th'>
                            <th>Номер</th>
                            <th>Код</th>
                            <th>Кол-во циклов</th>
                            <th>Кол-во упражнений</th>
                            <th>Время отдыха</th>
                            <th>Время тренировки</th>
                            <th>Тип</th>
                            <th colSpan={2}>Разминки</th>
                        </tr>
                        </thead>
                        <tbody>
                        {complexes ? complexes.map((item, index) =>
                            <tr className='complexes__table__row complexes__link table-tr__td' key={index}
                                onClick={() => handleClick(item)}
                            >
                                <td>{item.name}</td>
                                <td>{item.code || '-'}</td>
                                <td>{item.countCircles || '-'}</td>
                                <td>{item.countExercise || '-'}</td>
                                <td>{item.timeToRelax || '-'}</td>
                                <td>{item.timeToTraining || '-'}</td>
                                <td>{item.type || '-'}</td>
                                { item.warmUp.map((item, index) =>
                                <td key={index}>
                                    <div><strong>Название: </strong> {`${item.name || '-'} `}</div>
                                    <div><strong>Код: </strong>{`${item.code || '-'}`}</div>
                                    <div><strong>Пол: </strong>{`${item.gender || '-'}`}</div>
                                    {/*<div><strong>Важно: </strong>{`${item.important || '-'}`}</div>*/}
                                    <div><strong>Инвентарь: </strong>{`${item.inventory || '-'}`}</div>
                                    <div><strong>Мышцы: </strong>{`${item.muscles || '-'}`}</div>
                                </td>
                                )}
                                {/*{ item.circles.slice(0,1).map((item, index) =>*/}
                                {/*    <td key={index}>*/}
                                {/*        <div className='circles__header'><strong>Циклы 1-5: </strong></div>*/}
                                {/*        { item.exercises.map((item, index) =>*/}
                                {/*            <div key={index}>*/}
                                {/*                <div className='circles-exercise__header'><strong>Упражнение № </strong> {index + 1}</div>*/}
                                {/*                <div><strong>Название упражнения: </strong>{item.exercise.name || '-'}</div>*/}
                                {/*                <div><strong>Пол: </strong>{item.exercise.gender || '-'}</div>*/}
                                {/*                /!*<div><strong>Важно: </strong>{item.exercise.important || '-'}</div>*!/*/}
                                {/*                <div><strong>Инвентарь: </strong>{item.exercise.inventory || '-'}</div>*/}
                                {/*                <div><strong>Мышцы: </strong>{item.exercise.muscles || '-'}</div>*/}
                                {/*            </div>*/}
                                {/*        )}*/}
                                {/*    </td>*/}
                                {/*)}*/}
                            </tr>
                        ) : null}
                        </tbody>
                    </table> : <Loader/>
                }
            </div>
        </Template>
    )
}

export default ComplexesScreen;
