import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
import Start			from './Screens/Start';
import Login			from './Screens/Login';

import Dashboard		from './Screens/Dashboard';

import Train 			from "./Screens/Train";
import Training 		from "./Screens/Training";
import AddTrain			from "./Screens/AddTrain";

import Exercises 		from "./Screens/Exercises";
import Exercise			from "./Screens/Exercise";
import AddExercise 		from "./Screens/AddExercise";

import Complexes 		from "./Screens/Complexes";
import Complex          from "./Screens/Complex";
import AddComplex       from "./Screens/AddComplex";

import Categories 		from "./Screens/Categories";
import Category 		from "./Screens/Category";
import AddCategory      from "./Screens/AddCategory";

import Items 			from "./Screens/Items";
import Item 			from "./Screens/Item";
import AddItem 			from "./Screens/AddItem";

import Orders 			from "./Screens/Orders";

import Sections 		from "./Screens/Sections";
import Section 			from "./Screens/Section";
import AddSection       from "./Screens/AddSection";

import Food 			from "./Screens/Food";

import Users			from './Screens/Users';
import User				from './Screens/User';

import Clients 			from "./Screens/Clients";
import References 		from "./Screens/References";

import Settings 		from "./Screens/Settings";
import Setting 			from "./Screens/Setting";
import AddSetting 		from "./Screens/AddSetting";

import Error			from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/login' component={Login} exact />

			<Route path='/dashboard' component={Dashboard} exact />

			<Route path='/train' component={Train} exact />
			<Route path='/training' component={Training} exact />
			<Route path='/addTrain' component={AddTrain} exact />

			<Route path='/exercises' component={Exercises} exact />
			<Route path='/exercise' component={Exercise} exact />
			<Route path='/addExercise' component={AddExercise} exact />

			<Route path='/complexes' component={Complexes} exact />
			<Route path='/complex' component={Complex} exact />
			<Route path='/addComplex' component={AddComplex} exact />

			<Route path='/dish' component={Food} exact />

			<Route path='/users' component={Users} exact />
			<Route path='/user' component={User} exact />
			<Route path='/user/:id' component={User} exact />

			<Route path='/clients' component={Clients} exact />
			<Route path='/references' component={References} exact />

			<Route path='/settings' component={Settings} exact />
			<Route path='/setting' component={Setting} exact />
			<Route path='/addSetting' component={AddSetting} exact />

			<Route path='/categories' component={Categories} exact />
			<Route path='/category' component={Category} exact />
			<Route path='/addCategory' component={AddCategory} exact />


			<Route path='/items' component={Items} exact />
			<Route path='/item' component={Item} exact />
			<Route path='/addItem' component={AddItem} exact />

			<Route path='/orders' component={Orders} exact />

			<Route path='/sections' component={Sections} exact />
			<Route path='/section' component={Section} exact />
			<Route path='/addSection' component={AddSection} exact />


			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;