import Template from "../../Components/Template";
import {useHistory} from "react-router-dom";
import {create} from "../../Models/Items";
import React, {useContext, useState} from "react";
import { useForm, Controller } from 'react-hook-form';
import {Context} from "../../Components/Provider";

// styles
import './styles.css'

const AddItem = () => {

    const { control, handleSubmit, register, setValue, getValues } = useForm();
    const {categoriesData} = useContext(Context);
    // const [fields, setFields] = useState([]);
    // const [otherField, setOtherField] = useState('');

    const history = useHistory();

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/items');
    }

    // const addField = () => {
    //     const newField = {
    //         key: '',
    //         value: '',
    //     };
    //     setFields([...fields, newField]);
    // };
    //
    // const removeField = (index) => {
    //     const updatedFields = [...fields];
    //     if (index >= 0 && index < updatedFields.length) {
    //         updatedFields.splice(index, 1);
    //         setFields(updatedFields);
    //     }
    // };

    const onSubmit = async (data) => {

        const { name, description, image, price, categoryName, article, characters, size, color } = data;

        let sizeArray = null;
        let categoryId = '';

        if (size.includes(',')) {
            sizeArray = size.split(',').map(Number);
        }

        if (categoriesData.length > 0) {
            categoryId = categoriesData.find((categoryItem) => categoryItem.name === categoryName)?._id;
        }

        // const charactersObject = characters.reduce((acc, character) => {
        //     acc[character.key] = character.value;
        //     return acc;
        // }, {});

        const postData = {
            "name" : name,
            "description" : description,
            // "image" : image,
            "price" : price,
            "categoryId" : categoryId,
            "article" : article,
            // "characters" : charactersObject,
            "size" : sizeArray ?? size,
            "color" : color,
            "images": "",
        }

        // console.log(postData);
        await create(postData);
    };

    function handleClick() {
        alert('Предмет добавлен');
    }

    return (
        <Template>
            <div className='exercise__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='exercise__header left'>Создание предмета</h4>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='settings__form-container'>
                <label>
                    <span className='exercise__form-container__name mt'>Название</span>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input className='settings__form-container__input' {...field} />}
                    />
                </label>
                <label>
                    <span className='exercise__form-container__name mt'>Артикул</span>
                    <Controller
                        name="article"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input className='settings__form-container__input' {...field} />}
                    />
                </label>
                <label>
                    <span className='exercise__form-container__name mt'>Описание</span>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input className='settings__form-container__input' {...field} />}
                    />
                </label>
                {/*<label>*/}
                {/*    <span className='exercise__form-container__name mt'>Изображение</span>*/}
                {/*    <Controller*/}
                {/*        name="image"*/}
                {/*        control={control}*/}
                {/*        defaultValue=""*/}
                {/*        render={({ field }) => <input className='settings__form-container__input' {...field} />}*/}
                {/*    />*/}
                {/*</label>*/}
                <label>
                    <span className='exercise__form-container__name mt'>Цена</span>
                    <Controller
                        name="price"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input className='settings__form-container__input' {...field} />}
                    />
                </label>
                <label>
                    <span className='exercise__form-container__name mt'>Цвет</span>
                    <Controller
                        name="color"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input className='settings__form-container__input' {...field} />}
                    />
                </label>
                <label>
                    <span className='exercise__form-container__name mt'>Размер(-ы)</span>
                    <Controller
                        name="size"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input className='settings__form-container__input' {...field} />}
                    />
                    <p className='add__item__size__description'>При наличии нескольких размеров напишите их через запятую</p>
                </label>
                {/*    <span className='exercise__form-container__name mt'>Характеристика(-и)</span>*/}
                {/*{fields.map((field, index) => (*/}
                {/*    <div key={index} className='add__item__container__row'>*/}
                {/*        <label>*/}
                {/*            <span className='exercise__form-container__name mt'>Характеристика № {index + 1}</span>*/}
                {/*            <Controller*/}
                {/*                name={`characters[${index}].key`}*/}
                {/*                control={control}*/}
                {/*                defaultValue={field.key}*/}
                {/*                render={({ field }) => <input className='settings__form-container__input' {...field} />}*/}
                {/*            />*/}
                {/*        </label>*/}
                {/*        <label>*/}
                {/*            <span className='exercise__form-container__name mt'>Значение № {index + 1}</span>*/}
                {/*            <Controller*/}
                {/*                name={`characters[${index}].value`}*/}
                {/*                control={control}*/}
                {/*                defaultValue={field.value}*/}
                {/*                render={({ field }) => <input className='settings__form-container__input' {...field} />}*/}
                {/*            />*/}
                {/*        </label>*/}
                {/*        <button className='add__item__delete-button' type="button" onClick={() => removeField(index)}>Удалить</button>*/}
                {/*    </div>*/}
                {/*))}*/}
                {/*<button type="button" className='add__item__add-button' onClick={addField}>Добавить</button>*/}
                <label>
                    <label htmlFor="categoryName" className='exercise__form-container__name mt'>Выберите категорию</label>
                    <Controller
                        name="categoryName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <select {...field} className='categories__form-container__select'>
                                <option value="" disabled> </option>
                                {categoriesData.length > 0 && categoriesData.map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                            </select>
                        )}
                    />
                </label>
                <div className='settings__form-container__button-container'>
                    <button onClick={handleClick} className='settings__form-container__button' type="submit">Сохранить</button>
                </div>
            </form>
        </Template>
    );
}

export default AddItem;