import {API} from '../Constants';

import axios from 'axios';

const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const login = async (endpoint, data) => {
	const url = `${API.url}${endpoint}`;
	const headers = {
		'Content-Type': 'application/json',
	};
	const options = {
		method: 'POST',
		headers,
		data: JSON.stringify(data),
	};
	try {
		const response = await axios(url, options);
		if (isDevelopmentMode) console.log(response);
		return response.data;
	} catch (error) {
		console.log('error:', error.response);
		throw error.response.data;
	}
};

const request = async (endpoint, data) => {
	const url = `${API.url}${endpoint}`;
	const token = localStorage.getItem('token');
	const headers = {
		'X-Token': API.key,
		'Authorization' : token,
		'Content-Type': 'application/json',
	};
	const options = {
		method: 'POST',
		headers,
		data: data,
	};
	try {
		const response = await axios(url, options);
		if (isDevelopmentMode) console.log(response);
		return response.data;
	} catch (error) {
		console.log('error:', error.response);
		throw error.response.data;
	}
};

const put = async (endpoint, data) => {
	const url = `${API.url}${endpoint}`;
	const token = localStorage.getItem('token');
	const headers = {
		'X-Token': API.key,
		'Authorization' : token,
		'Content-Type': 'application/json',
	};
	const options = {
		method: 'PUT',
		headers,
		data: data,
	};
	try {
		const response = await axios(url, options);
		if (isDevelopmentMode) console.log(response);
		return response.data;
	} catch (error) {
		console.log('error:', error.response);
		throw error.response.data;
	}
};

const get = async (endpoint) => {
	const url = `${API.url}${endpoint}`;
	const token = localStorage.getItem('token');
	const headers = {
		'X-Token': API.key,
		'Authorization' : token,
	};

	try {
		const response = await axios.get(url, {
			headers,
		});
		if (isDevelopmentMode) console.log(response);
		return response.data;
	} catch (error) {
		console.log('error:', error.response);
		throw error.response.data;
	}
};


const Http = {
	login,
	request,
	get,
	put,
};

export default Http;