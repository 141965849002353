import Template from "../../Components/Template";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {getSections} from "../../Models/Sections";
import Loader from "../../Components/Loader";

const Sections = () => {

    const [sections, setSections] = useState([]);

    const history = useHistory();

    useEffect(() => {
        const getData = async () => {

            try {
                const result = await getSections();
                setSections(result);

            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    const handleClick = (item) => {
        // передаем параметры в объекте вторым аргументом
        history.push('/section', {
            id: item._id,
            name: item.name,
        });
    }

    const handleGoToAddSection = (event) => {
        event.preventDefault();
        history.push('/addSection');
    }

    return (
        <Template>
            <div className='exercises__container'>
                <div className='exercise__container__head'>
                    <h3 className='exercises__header'>Секции</h3>
                    <button onClick={handleGoToAddSection} className='exercise__header right add'>Добавить</button>
                </div>
                {   sections ?
                    <table className='exercises__table'>
                        <thead className='exercises__table__header'>
                        <tr className='exercises__table__row table-tr__th'>
                            <th>№</th>
                            <th>Название</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sections ? sections.map((item, index) =>
                            <tr className='exercises__table__row exercises__link table-tr__td' key={index}
                                onClick={() => handleClick(item)} >
                                <td>{index + 1}</td>
                                <td>{item.name || '-'}</td>
                            </tr>
                        ) : null}
                        </tbody>
                    </table> : <Loader/>
                }
            </div>
        </Template>)
}

export default Sections;