import HttpMarket from "../../Globals/HttpMarket";

const getItems			= async ()			=> await HttpMarket.get('admin/items');

const create			= async (data)		=> await HttpMarket.request('admin/items', data);

const update		= async (id, data)	    => await HttpMarket.put(`admin/items/${id}`, data);


export {
    getItems,
    create,
    update
}