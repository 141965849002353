import Http from '../../Globals/Http';

const get			= async ()			=> await Http.get('admin/settings');

const create			= async (data)		=> await Http.request('admin/settings', data);
const update		= async (id, data)	=> await Http.put(`admin/settings/${id}`, data);


export {
    get,
    create,
    update
}