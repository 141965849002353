import {useHistory, useLocation} from "react-router-dom";
import React, {useContext, useState} from "react";

import {create} from "../../Models/Training";

import Template from "../../Components/Template";
import {Context} from "../../Components/Provider";
import {get} from "../../Models/Complex";



const AddTrainScreen = () => {

    const [warmUpLength, setWarmUpLength] = useState('');
    const [warmUpJointLength, setWarmUpJointLength] = useState('');
    const [hitchLength, setHitchLength] = useState('');
    const [trainExercisesLength, setTrainExercisesLength] = useState('');
    const {setTraining, setTrainingCash} = useContext(Context);

    const history = useHistory();

    const handleWarmUpLengthChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setWarmUpLength(Number(value));
        }
    };

    const handleWarmUpJointLengthChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setWarmUpJointLength(Number(value));
        }
    };

    const handleHitchLengthChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setHitchLength(Number(value));
        }
    };

    const handleTrainExercisesLengthChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setTrainExercisesLength(Number(value));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const values = Object.fromEntries(formData.entries());

        const warmUp = Array(warmUpLength).fill(null).map((_, index) => ({
            name: formData.get(`warmUpName[${index}]`),
            code: formData.get(`warmUpCode[${index}]`),
            gender: formData.get(`warmUpGender[${index}]`),
            important: formData.get(`warmUpImportant[${index}]`),
            inventory: formData.get(`warmUpInventory[${index}]`),
            muscles: formData.get(`warmUpMuscles[${index}]`),
            sectionName: formData.get(`warmUpSectionName[${index}]`),
        }));

        const warmUpJoint = Array(warmUpJointLength).fill(null).map((_, index) => ({
            name: formData.get(`warmUpJointName[${index}]`),
            code: formData.get(`warmUpJointCode[${index}]`),
            gender: formData.get(`warmUpJointGender[${index}]`),
            muscles: formData.get(`warmUpJointMuscles[${index}]`),
            sectionName: formData.get(`warmUpJointSectionName[${index}]`),
        }));

        const hitch = Array(hitchLength).fill(null).map((_, index) => ({
            name: formData.get(`hitchName[${index}]`),
            code: formData.get(`hitchCode[${index}]`),
            gender: formData.get(`hitchGender[${index}]`),
            important: formData.get(`hitchImportant[${index}]`),
            inventory: formData.get(`hitchInventory[${index}]`),
            muscles: formData.get(`hitchMuscles[${index}]`),
            sectionName: formData.get(`hitchSectionName[${index}]`),
        }));

        const trainExercises = Array(trainExercisesLength).fill(null).map((_, index) => {
            const exercise = {
                "code": formData.get(`trainExerciseCode[${index}]`),
                "gender" : formData.get(`trainExerciseGender[${index}]`),
                "inventory": formData.get(`trainExerciseInventory[${index}]`),
                "muscles": formData.get(`trainExerciseMuscles[${index}]`),
                "name": formData.get(`trainExerciseName[${index}]`),
                "replace": formData.get(`trainExerciseReplace[${index}]`),
                "replaceForSick": formData.get(`trainExerciseReplaceForSick[${index}]`),
                "sectionName": formData.get(`trainExerciseSectionName[${index}]`),
                "technique": formData.get(`trainExerciseTechnique[${index}]`),
            };
            const options = {
                "countRepeat": formData.get(`trainExerciseCountRepeat[${index}]`),
                "countTimes": formData.get(`trainExerciseCountTimes[${index}]`),
                "timeRelax": formData.get(`trainExerciseTimeRelax[${index}]`),
                "timeToDo": formData.get(`trainExerciseTimeToDo[${index}]`),
            };
            return { exercise, options };
        });

        if (trainExercises.length !== 0) {
                trainExercises.forEach(exercise => {
                    let replaceValue = exercise.exercise.replace.replace(/[^0-9,]/g, '');
                    let replaceForSickValue = exercise.exercise.replaceForSick.replace(/[^0-9,]/g, '');

                    exercise.exercise.replace = replaceValue.split(",");
                    exercise.exercise.replaceForSick = replaceForSickValue.split(",");
                });
            }

        values.exercises = trainExercises;
        values.hitch = hitch;
        values.warmUpJoint = warmUpJoint;
        values.warmUp = warmUp;

        const newTrain = {
            "code" : values.code,
            "countCircles" : values.countCircles,
            "countExercise" : values.countExercise,
            "exercises" : values.exercises,
            "gender" : values.gender,
            "hitch" : values.hitch,
            "level" : values.level,
            "name" : values.name,
            "place" : values.place,
            "sectionName" : values.sectionName,
            "target" : values.target,
            "task" : values.task,
            "timeToRelax" : values.timeToRelax,
            "timeToTraining" : values.timeToTraining,
            "type" : values.type,
            "warmUp" : values.warmUp,
            "warmUpJoint" : values.warmUpJoint,
        };

        // const url = 'http://example.com/api/endpoint';
        // fetch(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(newTrain)
        // })
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error('Network response was not ok');
        //         }
        //         return response.json();
        //     })
        //     .then(data => {
        //         console.log(data); // Обработка успешного ответа
        //     })
        //     .catch(error => {
        //         console.error('Error:', error); // Обработка ошибок
        //     });

        await create(newTrain);

        const result = await get();

        setTraining(result);
        setTrainingCash(result);
    };

    const renderWarmUp = () => {
        if (warmUpLength > 0 && warmUpLength < 20) {
            const warmUp = Array(warmUpLength).fill(null);
            return warmUp.map((item, index) =>
                <div key={index}>
                    <div className='complex__form-container__name-header circle'>{`Разминка № ${index + 1}`}</div>
                    <div className='warm__container'>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Название разминки</span>
                            <input className='complex__form-container__input-same' type="text"  name={`warmUpName[${index}]`}/>
                        </label>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Код</span>
                            <input className='complex__form-container__input-same' type="text"  name={`warmUpCode[${index}]`}/>
                        </label>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Пол</span>
                            <input className='complex__form-container__input-same' type="text"  name={`warmUpGender[${index}]`}/>
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name '>Важно</span>
                            <textarea className='complex__form-container__input-same' type="text"  name={`warmUpImportant[${index}]`}/>
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name'>Инвентарь</span>
                            <textarea className='complex__form-container__input-same' type="text"  name={`warmUpInventory[${index}]`}/>
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name'>Мышцы</span>
                            <textarea className='complex__form-container__input-same' type="text"  name={`warmUpMuscles[${index}]`}/>
                        </label>
                        <label className='warm__three'>
                            <span className='complex__form-container__name'>Название секции</span>
                            <textarea className='complex__form-container__input-same' type="text"  name={`warmUpSectionName[${index}]`}/>
                        </label>
                    </div>
                </div>);
        } else {
            return null;
        }
    };

    const renderWarmUpJoint = () => {
        if (warmUpJointLength > 0 && warmUpJointLength < 20) {
            const warmUpJoint = Array(warmUpJointLength).fill(null);
            return warmUpJoint.map((item, index) =>
                <div key={index}>
                    <div className='complex__form-container__name-header circle'>{`Разминка сустава № ${index + 1}`}</div>
                    <div className='warm__container'>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Название разминки</span>
                            <input className='complex__form-container__input-same' type="text" name={`warmUpJointName[${index}]`} />
                        </label>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Код</span>
                            <input className='complex__form-container__input-same' type="text" name={`warmUpJointCode[${index}]`} />
                        </label>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Мышцы</span>
                            <input className='complex__form-container__input-same' type="text" name={`warmUpJointMuscles[${index}]`} />
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name'>Пол</span>
                            <input className='complex__form-container__input-same' type="text" name={`warmUpJointGender[${index}]`} />
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name'>Название секции</span>
                            <textarea className='complex__form-container__input-same' type="text" name={`warmUpJointSectionName[${index}]`} />
                        </label>
                    </div>
                </div>);
        } else {
            return null;
        }
    };

    const renderHitch = () => {
        if (hitchLength > 0 && hitchLength < 20) {
            const hitch = Array(hitchLength).fill(null);
            return hitch.map((item, index) =>
                <div key={index}>
                    <div className='complex__form-container__name-header circle'>{`Заминка № ${index + 1}`}</div>
                    <div className='warm__container'>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Название заминки</span>
                            <input className='complex__form-container__input-same' type="text" name={`hitchName[${index}]`} />
                        </label>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Код</span>
                            <input className='complex__form-container__input-same' type="text" name={`hitchCode[${index}]`} />
                        </label>
                        <label className='warm__one'>
                            <span className='complex__form-container__name'>Мышцы</span>
                            <input className='complex__form-container__input-same' type="text" name={`hitchMuscles[${index}]`}/>
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name'>Пол</span>
                            <input className='complex__form-container__input-same' type="text" name={`hitchGender[${index}]`}/>
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name'>Название секции</span>
                            <input className='complex__form-container__input-same' type="text" name={`hitchSectionName[${index}]`}/>
                        </label>
                        <label className='warm__two'>
                            <span className='complex__form-container__name '>Важно</span>
                            <textarea className='complex__form-container__input-same' type="text" name={`hitchImportant[${index}]`}/>
                        </label>
                        <label className='warm__three'>
                            <span className='complex__form-container__name'>Инвентарь</span>
                            <textarea className='complex__form-container__input-same' type="text" name={`hitchInventory[${index}]`}/>
                        </label>
                    </div>
                </div>);
        } else {
            return null;
        }
    };

    const renderTrainExercises = () => {
        if (trainExercisesLength > 0 && trainExercisesLength < 20) {
            const trainExercises = Array(trainExercisesLength).fill(null);
            return trainExercises.map((item, index) =>
                <div key={index}>
                    <div className='train__form-container__name-header-two'>{`Упражнение № ${index + 1}`}</div>
                    <div className='train__exercise__container'>
                        <label className='exercise__one'>
                            <span className='exercise__form-container__name'>Код</span>
                            <input className='exercise__form-container__input' type="text" name={`trainExerciseCode[${index}]`}  />
                        </label>
                        <label className='train__exercise__one'>
                            <span className='exercise__form-container__name'>Пол</span>
                            <input className='exercise__form-container__input' type="text" name={`trainExerciseGender[${index}]`}  />
                        </label>
                        <label className='train__exercise__one'>
                            <span className='exercise__form-container__name'>Техника</span>
                            <textarea className='exercise__form-container__input' type="text" name={`trainExerciseTechnique[${index}]`}  />
                        </label>
                        <label className='train__exercise__two'>
                            <span className='exercise__form-container__name'>Инвентарь</span>
                            <input className='exercise__form-container__input' type="text" name={`trainExerciseInventory[${index}]`}  />
                        </label>
                        <label>
                            <span className='exercise__form-container__name'>Мышцы</span>
                            <textarea className='exercise__form-container__input' type="text" name={`trainExerciseMuscles[${index}]`}  />
                        </label>
                        <label>
                            <span className='exercise__form-container__name'>Название</span>
                            <textarea className='exercise__form-container__input' type="text" name={`trainExerciseName[${index}]`}  />
                        </label>
                        <label>
                            <span className='exercise__form-container__name'>Похожее упражнение</span>
                            <input className='exercise__form-container__input' type="text" name={`trainExerciseReplace[${index}]`}  />
                        </label>
                        <label>
                            <span className='exercise__form-container__name'>Похожее упражнение при болезни</span>
                            <input className='exercise__form-container__input' type="text" name={`trainExerciseReplaceForSick[${index}]`}  />
                        </label>
                        <label>
                            <span className='exercise__form-container__name'>Название секции</span>
                            <textarea className='exercise__form-container__input' type="text" name={`trainExerciseSectionName[${index}]`}  />
                        </label>
                        <label>
                            <span className='exercise__form-container__name'>Количество повторов</span>
                            <textarea className='exercise__form-container__input' type="text" name={`trainExerciseCountRepeat[${index}]`}  />
                        </label>
                        <label>
                            <span className='exercise__form-container__name'>Количество раз</span>
                            <textarea className='exercise__form-container__input' type="text" name={`trainExerciseCountTimes[${index}]`}  />
                        </label>
                        <label>
                            <span className='exercise__form-container__name'>Время отдыха</span>
                            <textarea className='exercise__form-container__input' type="text" name={`trainExerciseTimeRelax[${index}]`}  />
                        </label>
                        <label>
                            <span className='exercise__form-container__name'>Время действия</span>
                            <textarea className='exercise__form-container__input' type="text" name={`trainExerciseTimeToDo[${index}]`}  />
                        </label>
                    </div>
                </div>);
        } else {
            return null;
        }
    };

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/training');
    }

    function handleClick() {
        alert('Тренировка добавлена');
    }

    return (
        <Template>
            <div className='exercise__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='exercise__header left'>Создание тренировки</h4>
                </div>
            </div>
                <form className='train__form-container'
                      onSubmit={handleSubmit}
                >
                    <div className='train__form-container__grid'>
                        <div className='train__common__container'>
                            <label className='train__common__one'>
                                <span className='train__form-container__name'>Название</span>
                                <input className='train__form-container__input' type="text" name="name" />
                            </label>
                            <label className='train__common__one'>
                                <span className='train__form-container__name'>Код</span>
                                <input className='train__form-container__input' type="text" name="code" />
                            </label>
                            <label className='train__common__one'>
                                <span className='train__form-container__name'>Кол-во циклов</span>
                                <input className='train__form-container__input' type="text" name="countCircles" />
                            </label>
                            <label className='train__common__one'>
                                <span className='train__form-container__name'>Кол-во упражнений</span>
                                <input className='train__form-container__input' type="text" name="countExercise" value={trainExercisesLength} onChange={handleTrainExercisesLengthChange}/>
                            </label>
                            {/*<label className='train__common__one'>*/}
                            {/*    <span className='train__form-container__name'>Кол-во суперсетов</span>*/}
                            {/*    <input className='train__form-container__input' type="text" name="countExercise" />*/}
                            {/*</label>*/}
                            <label className='train__common__two'>
                                <span className='train__form-container__name'>Пол</span>
                                <input className='train__form-container__input' type="text" name="gender" />
                            </label>
                            <label className='train__common__two'>
                                <span className='train__form-container__name'>Уровень тренировки</span>
                                <input className='train__form-container__input' type="text" name="level" />
                            </label>
                            <label className='train__common__two'>
                                <span className='train__form-container__name'>Место</span>
                                <input className='train__form-container__input' type="text" name="place" />
                            </label>
                            <label className='train__common__two'>
                                <span className='train__form-container__name'>Название секции</span>
                                <input className='train__form-container__input' type="text" name="sectionName" />
                            </label>
                            <label className='train__common__three'>
                                <span className='train__form-container__name'>Цель тренировки</span>
                                <input className='train__form-container__input' type="text" name="target" />
                            </label>
                            <label className='train__common__three'>
                                <span className='train__form-container__name'>Время отдыха</span>
                                <input className='train__form-container__input' type="text" name="timeToRelax" />
                            </label>
                            <label className='train__common__three'>
                                <span className='train__form-container__name'>Время тренировки</span>
                                <input className='train__form-container__input' type="text" name="timeToTraining" />
                            </label>
                            <label className='train__common__three'>
                                <span className='train__form-container__name'>Тип тренировки</span>
                                <input className='train__form-container__input' type="text" name="type" />
                            </label>
                            <label className='train__common__four'>
                                <span className='train__form-container__name'>Задача</span>
                                <textarea className='train__form-container__input' type="text" name="task" />
                            </label>
                            <label className='train__common__four'>
                                <span className='train__form-container__name'>Кол-во разминок</span>
                                <input className='train__form-container__input' type="text" value={warmUpLength} onChange={handleWarmUpLengthChange}/>
                            </label>
                            <label className='train__common__four'>
                                <span className='train__form-container__name'>Кол-во разминок сустава</span>
                                <input className='train__form-container__input' type="text" value={warmUpJointLength} onChange={handleWarmUpJointLengthChange}/>
                            </label>
                            <label className='train__common__four'>
                                <span className='train__form-container__name'>Кол-во заминок</span>
                                <input className='train__form-container__input' type="text" value={hitchLength} onChange={handleHitchLengthChange}/>
                            </label>
                            <div className='train__warm-up__container'>
                                {   warmUpLength > 0 && warmUpLength < 20 &&
                                    <span className='train__form-container__name-header'>Разминки</span>
                                }
                                {renderWarmUp()}
                            </div>
                            <div>
                                {   warmUpJointLength > 0 && warmUpJointLength < 20 &&
                                    <span className='train__form-container__name-header'>Разминки cустава</span>
                                }
                                {renderWarmUpJoint()}
                            </div>
                            <div>
                                {   hitchLength > 0 && hitchLength < 20 &&
                                    <span className='train__form-container__name-header'>Заминки</span>
                                }
                                {renderHitch()}
                            </div>
                            <div>
                                {   trainExercisesLength > 0 && trainExercisesLength < 20 &&
                                    <span className='train__form-container__name-header'>Упражнения</span>
                                }
                                {renderTrainExercises()}
                            </div>
                        </div>
                    </div>
                    <div className='complex__form-container__button-container'>
                        <button onClick={handleClick} className='complex__form-container__button ' type="submit">Сохранить</button>
                    </div>
                </form>

        </Template>
)}

export default AddTrainScreen;