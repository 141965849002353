import { createContext, useState } from "react";

export const Context = createContext();

const Provider = ({children}) => {
    const [exercise, setExercise] = useState(null);
    const [cash, setCash] = useState(null);
    const [complexes, setComplexes] = useState(null);
    const [complexesCash, setComplexesCash] = useState(null);
    const [training, setTraining] = useState(null);
    const [trainingCash, setTrainingCash] = useState(null);
    const [sectionsData, setSectionsData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);

    return (
        <Context.Provider value={{
            exercise,
            setExercise,
            cash,
            setCash,
            complexes,
            setComplexes,
            complexesCash,
            setComplexesCash,
            training,
            setTraining,
            trainingCash,
            setTrainingCash,
            sectionsData,
            setSectionsData,
            categoriesData,
            setCategoriesData,
        }}>
            {children}
        </Context.Provider>
    );
}

export default Provider;