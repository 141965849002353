import {useHistory} from "react-router-dom";
import React, {useContext, useState} from "react";

import {create, get} from "../../Models/Exercise";

import Template from "../../Components/Template";
import {Context} from "../../Components/Provider";

const AddExerciseScreen = () => {

    const {setExercise, setCash} = useContext(Context);
    // const imgUrl = 'https://s3.super-appz.ru/download/fitness/images/exercises';
    // const videoUrl = 'https://s3.super-appz.ru/download/fitness/video/exercises';
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const values = Object.fromEntries(formData.entries());


        let replaceValue = values.replace.replace(/[^0-9,]/g, '');
        let replaceForSickValue = values.replaceForSick.replace(/[^0-9,]/g, '');

        values.replace = replaceValue.split(",");
        values.replaceForSick = replaceForSickValue.split(",");

        await create(values);

        const result = await get();

        setExercise(result);
        setCash(result);
    };

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/exercises');
    }

    function handleClick() {
        alert('Упражнение добавлено');
    }

    return (
        <Template>
            <div className='exercise__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='exercise__header left'>Создание упражнения</h4>
                </div>
            </div>
            <form className='exercise__form-container'
             onSubmit={handleSubmit}
            >
                <div className='exercise__form-container__grid'>
                    <label>
                        <span className='exercise__form-container__name'>Код</span>
                        <input className='exercise__form-container__input' type="text" name="code" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Для детей</span>
                        <select className='exercise__form-container__input' type="boolean" name="forChildren">
                            <option value={false}>Нет</option>
                            <option value={true}>Да</option>
                        </select>
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Пол</span>
                        <input className='exercise__form-container__input' type="text" name="gender" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Важно</span>
                        <textarea className='exercise__form-container__input' type="text" name="important" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Инвентарь</span>
                        <input className='exercise__form-container__input' type="text" name="inventory" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Мышцы</span>
                        <textarea className='exercise__form-container__input' type="text" name="muscles" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Название</span>
                        <textarea className='exercise__form-container__input' type="text" name="name" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Похожее упражнение</span>
                        <input className='exercise__form-container__input' type="text" name="replace" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Похожее упражнение при болезни</span>
                        <input className='exercise__form-container__input' type="text" name="replaceForSick" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Название секции</span>
                        <textarea className='exercise__form-container__input' type="text" name="sectionName" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Исходная позиция</span>
                        <textarea className='exercise__form-container__input' type="text" name="startingPosition" />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Техника</span>
                        <textarea className='exercise__form-container__input' type="text" name="technique" />
                    </label>
                </div>
                <div className='exercise__form-container__button-container'>
                    <button onClick={handleClick} className='exercise__form-container__button ' type="submit">Сохранить</button>
                </div>
            </form>
        </Template>
    );
};

export default AddExerciseScreen;