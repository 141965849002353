import React, {useContext, useEffect, useState} from 'react';
import { useLocation, useHistory } from "react-router-dom";

// styles
import './styles.css';

import Template from "../../Components/Template";
import Loader from "../../Components/Loader";
import {update, get} from "../../Models/Complex";
import {Context} from "../../Components/Provider";

const ComplexScreen = () => {

    const {setComplexes, setComplexesCash} = useContext(Context);
    const videoUrl = 'https://s3.super-appz.ru/download/fitness/video/complexes';
    const videoExercisesUrl = 'https://s3.super-appz.ru/download/fitness/video/exercises';
    const imgUrl = 'https://s3.super-appz.ru/download/fitness/images/exercises';
    const location = useLocation();
    const history = useHistory();

    const [formData, setFormData] = useState({
        id: location.state.id,
        code: location.state?.code || '-',
        countCircles: location.state?.countCircles || '-',
        countExercise: location.state?.countExercise || '-',
        name: location.state?.name || '-',
        task: location.state?.task || '-',
        timeToRelax: location.state?.timeToRelax || '-',
        timeToTraining: location.state?.timeToTraining || '-',
        type: location.state?.type || '-',
        warmUp: location.state?.warmUp || [],
        circles: location.state?.circles || [],
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleWarmUpChange = (index, field, value) => {
        const updatedWarmUp = [...formData.warmUp];
        updatedWarmUp[index][field] = value;
        setFormData({ ...formData, warmUp: updatedWarmUp });
    }

    // Функция обработки изменений в полях упражнений
    const handleCirclesChange = (circleIndex, exerciseIndex, field, value) => {
        // Обновляем значение в formData
        const updatedFormData = { ...formData };
        updatedFormData.circles.forEach(circle => {
            circle.exercises[exerciseIndex].exercise[field] = value;
            circle.exercises[exerciseIndex].options[field] = value;
        });
        setFormData(updatedFormData);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const warmUpData = formData.warmUp.map((item) => {
            return {
                name: item.name,
                code: item.code,
                gender: item.gender,
                important: item.important,
                inventory: item.inventory,
                startingPosition: item.startingPosition,
                technique: item.technique,
                muscles: item.muscles,
            };
        });

        const circlesData = formData.circles.map((circle) => {
            return {
                exercises: circle.exercises.map((item) => {
                    return {
                            exercise: {
                                name: item.exercise.name,
                                code: item.exercise.code,
                                gender: item.exercise.gender,
                                important: item.exercise.important,
                                inventory: item.exercise.inventory,
                                startingPosition: item.exercise.startingPosition,
                                technique: item.exercise.technique,
                                muscles: item.exercise.muscles,
                            },
                            options: {
                                ...item.options,
                            },
                    };
                }),
            };
        });

        const updateComplex = {
            code: formData.code,
            countCircles: formData.countCircles,
            countExercise: formData.countExercise,
            task: formData.task,
            timeToRelax: formData.timeToRelax,
            timeToTraining: formData.timeToTraining,
            name: formData.name,
            type: formData.type,
            warmUp: warmUpData,
            circles: circlesData,
        };

        await update(location.state.id, updateComplex);
        const result = await get();
        // cache.set('exercises', result);
        setComplexes(result);
        setComplexesCash(result);
    };

    // console.log(location.state);

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/complexes');
    }


    function handleClick() {
        alert('Сохранено');
    }

    return (
        <Template>
            <div className='complex__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='complex__header left'>Редактирование комплекса</h4>
                </div>
                {/*<h4 className='complex__header right'>Фотографии/видео комплекса</h4>*/}
            </div>
            <form className='complex__form-container' onSubmit={handleSubmit}>
                <div className='complex__form-container__grid'>
                    <label>
                        <span className='complex__form-container__name'>Название</span>
                        <input className='complex__form-container__input' type="text" name="name" value={formData.name} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Код</span>
                        <input className='complex__form-container__input' type="text" name="code" value={formData.code} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Кол-во циклов</span>
                        <input className='complex__form-container__input' type="text" name="countCircles" value={formData.countCircles} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Кол-во упражнений</span>
                        <input className='complex__form-container__input' type="text" name="countExercise" value={formData.countExercise} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Задача</span>
                        <textarea className='complex__form-container__input' type="text" name="task" value={formData.task} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Время отдыха</span>
                        <input className='complex__form-container__input' type="text" name="timeToRelax" value={formData.timeToRelax} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Время тренировки</span>
                        <input className='complex__form-container__input' type="text" name="timeToTraining" value={formData.timeToTraining} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='complex__form-container__name'>Тип тренировки</span>
                        <input className='complex__form-container__input' type="text" name="type" value={formData.type} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='complex__form-container__name-header'>Разминки</span>
                        {
                            formData.warmUp.map((item, index) =>
                                <div key={index}>
                                    <div className='complex__form-container__name-header circle'>{`Разминка № ${index + 1}`}</div>
                                    <div className='warm__container'>
                                        <label className='warm__one'>
                                            <span className='complex__form-container__name'>Название разминки</span>
                                            <input className='complex__form-container__input-same' type="text" name="warmUp.name" value={item.name} onChange={event => handleWarmUpChange(index, 'name', event.target.value)} />
                                        </label>
                                        <label className='warm__one'>
                                            <span className='complex__form-container__name'>Код</span>
                                            <input className='complex__form-container__input-same' type="text" name="warmUp.code" value={item.code} onChange={event => handleWarmUpChange(index, 'code', event.target.value)} />
                                        </label>
                                        <label className='warm__one'>
                                            <span className='complex__form-container__name'>Пол</span>
                                            <input className='complex__form-container__input-same' type="text" name="warmUp.gender" value={item.gender} onChange={event => handleWarmUpChange(index, 'gender', event.target.value)} />
                                        </label>
                                        <label className='warm__two'>
                                            <span className='complex__form-container__name '>Важно</span>
                                            <textarea className='complex__form-container__input-same' type="text" name="warmUp.important" value={item.important} onChange={event => handleWarmUpChange(index, 'important', event.target.value)} />
                                        </label>
                                        <label className='warm__two'>
                                            <span className='complex__form-container__name'>Инвентарь</span>
                                            <textarea className='complex__form-container__input-same' type="text" name="warmUp.inventory" value={item.inventory} onChange={event => handleWarmUpChange(index, 'inventory', event.target.value)} />
                                        </label>
                                        <label className='warm__two'>
                                            <span className='complex__form-container__name'>Исходная позиция</span>
                                            <input className='complex__form-container__input-same' type="text" name="warmUp.startingPosition" value={item.startingPosition} onChange={event => handleWarmUpChange(index, 'startingPosition', event.target.value)} />
                                        </label>
                                        <label className='warm__three'>
                                            <span className='complex__form-container__name'>Техника</span>
                                            <textarea className='complex__form-container__input-same' type="text" name="warmUp.technique" value={item.technique} onChange={event => handleWarmUpChange(index, 'technique', event.target.value)} />
                                        </label>
                                        <label className='warm__three'>
                                            <span className='complex__form-container__name'>Мышцы</span>
                                            <textarea className='complex__form-container__input-same' type="text" name="warmUp.muscles" value={item.muscles} onChange={event => handleWarmUpChange(index, 'muscles', event.target.value)} />
                                        </label>
                                    </div>
                                    <div className='warm__video'>
                                        <div className='complex__form-container__name-header circle'>{`Видео разминки № ${index + 1}`}</div>
                                        <video src={`${videoUrl}/${item.code}/${item.code}.mp4`} controls width="640" height="360">
                                            Ваш браузер не поддерживает воспроизведение видео.
                                        </video>
                                    </div>
                                </div>
                            )}
                    </label>
                    <label>
                        <span className='complex__form-container__name-header'>{`Циклы 1 - ${formData.countCircles}`}</span>
                        {
                            formData.circles.slice(0, 1).map((circle, circleIndex) =>
                                <div key={circleIndex}>
                                    {
                                        circle.exercises.map((exercise, exerciseIndex) =>
                                            <div key={exerciseIndex}>
                                                <div className='complex__form-container__name-header circle'>{`Упражнение № ${exerciseIndex + 1}`}</div>
                                                <div className='circle__container'>
                                                    <label className='circle__one'>
                                                    <span className='complex__form-container__name'>Название упражения</span>
                                                    <input className='complex__form-container__input-same' type="text" name={`circles.exercises.exercise.name`} value={exercise.exercise.name} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'name', event.target.value)} />
                                                    </label>
                                                    <label className='circle__one'>
                                                        <span className='complex__form-container__name'>Код</span>
                                                        <input className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].exercise.code`} value={exercise.exercise.code} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'code', event.target.value)} />
                                                    </label>
                                                    <label className='circle__one'>
                                                        <span className='complex__form-container__name'>Пол</span>
                                                        <input className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].exercise.gender`} value={exercise.exercise.gender} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'gender', event.target.value)} />
                                                    </label>
                                                    <label className='circle__two'>
                                                        <span className='complex__form-container__name'>Важно</span>
                                                        <textarea className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].exercise.important`} value={exercise.exercise.important} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'important', event.target.value)} />
                                                    </label>
                                                    <label className='circle__two'>
                                                        <span className='complex__form-container__name'>Инвентарь</span>
                                                        <input className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].exercise.inventory`} value={exercise.exercise.inventory} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'inventory', event.target.value)} />
                                                    </label>
                                                    <label className='circle__two'>
                                                        <span className='complex__form-container__name'>Мышцы</span>
                                                        <input className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].exercise.muscles`} value={exercise.exercise.muscles} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'muscles', event.target.value)} />
                                                    </label>
                                                    <label className='circle__three'>
                                                        <span className='complex__form-container__name'>Исходная позиция</span>
                                                        <textarea className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].exercise.startingPosition`} value={exercise.exercise.startingPosition} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'startingPosition', event.target.value)} />
                                                    </label>
                                                    <label className='circle__three'>
                                                        <span className='complex__form-container__name'>Техника</span>
                                                        <textarea className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].exercise.technique`} value={exercise.exercise.technique} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'technique', event.target.value)} />
                                                    </label>
                                                    <label className='circle__three'>
                                                        <span className='complex__form-container__name'>Время на одно упражнение</span>
                                                        <textarea className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].options.countTimes`} value={exercise.options.countTimes} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'countTimes', event.target.value)} />
                                                    </label>
                                                    <label className='circle__four'>
                                                        <span className='complex__form-container__name'>Время отдыха на одно упражнение</span>
                                                        <textarea className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].options.timeRelax`} value={exercise.options.timeRelax} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'timeRelax', event.target.value)} />
                                                    </label>
                                                    <label className='circle__four'>
                                                        <span className='complex__form-container__name'>Время работы на одно упражнение</span>
                                                        <textarea className='complex__form-container__input-same' type="text" name={`circles[${circleIndex}].exercises[${exerciseIndex}].options.timeToDo`} value={exercise.options.timeToDo} onChange={event => handleCirclesChange(circleIndex, exerciseIndex, 'timeToDo', event.target.value)} />
                                                    </label>
                                                </div>
                                                    <div>
                                                        <div className='complex__form-container__name-header circle'>{`Фотографии упражнения № ${exerciseIndex + 1}`}</div>
                                                        <div className='complex__circle_img'>
                                                            <img className='complexes__form-container__img__item circle__img__one' src={`${imgUrl}/${exercise.exercise.code}/${exercise.exercise.code}-1.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__one' src={`${imgUrl}/${exercise.exercise.code}/${exercise.exercise.code}-2.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__two' src={`${imgUrl}/${exercise.exercise.code}/${exercise.exercise.code}-3.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__two' src={`${imgUrl}/${exercise.exercise.code}/${exercise.exercise.code}-4.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__three' src={`${imgUrl}/${exercise.exercise.code}/${exercise.exercise.code}-5.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                            <img className='complexes__form-container__img__item circle__img__three' src={`${imgUrl}/${exercise.exercise.code}/${exercise.exercise.code}-6.jpg`} onError={(e) => e.target.remove()} alt=""/>
                                                        </div>
                                                        <div className='exercise__video'>
                                                            <div className='complex__form-container__name-header circle circle__video__header'>{`Видео упражнения № ${exerciseIndex + 1}`}</div>
                                                            <video
                                                                // src={`${videoUrl}/${exercise.exercise.code.slice(0, 2) + 'T' + exercise.exercise.code.slice(2)}/${exercise.exercise.code.slice(0, 2) + 'T' + exercise.exercise.code.slice(2)}.mp4`}
                                                                src={`${videoExercisesUrl}/${exercise.exercise.code}/${exercise.exercise.code}.mp4`}
                                                                onError={(e) => e.target.remove()} controls
                                                                width="640" height="360">
                                                            </video>
                                                        </div>
                                                    </div>
                                            </div>
                                        )}
                                </div>
                            )}
                    </label>
                </div>
                <div className='complex__form-container__button-container'>
                    <button onClick={handleClick} className='complex__form-container__button ' type="submit">Сохранить</button>
                </div>
            </form>
        </Template>
    );
};

export default ComplexScreen;