import React, {useEffect, useState, useMemo, useContext} from 'react';
import Template from "../../Components/Template";
import {get} from "../../Models/Training";
import Loader from "../../Components/Loader";
import {useHistory} from "react-router-dom";
// import { LRUCache } from 'lru-cache';


// styles
import './styles.css';
import {Context} from "../../Components/Provider";

// const cache = new LRUCache({ max: 100 });

const TrainingScreen = () => {

    const history = useHistory();
    const {training, setTraining, trainingCash, setTrainingCash} = useContext(Context);

    // useEffect(() => {
    //     const getData = async () => {
    //         try {
    //             const result = await get();
    //             setTraining(result);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };
    //     getData();
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            // сначала проверяем, есть ли данные в кэше

            if (trainingCash) {
                setTraining(trainingCash);
                return;
            }

            // если данных нет в кэше, то делаем запрос
            try {

                const result = await get();
                setTraining(result);
                setTrainingCash(result);
                // сохраняем полученные данные в кэше
                // cache.set('training', result);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const handleClick = (item) => {
        // передаем параметры в объекте вторым аргументом
        history.push('/train', {
            id: item._id,
            code: item.code,
            countCircles: item.countCircles,
            countExercise: item.countExercise,
            exercises: item.exercises,
            level: item.level,
            gender: item.gender,
            hitch: item.hitch,
            name: item.name,
            place: item.place,
            sectionName : item.sectionName,
            target: item.target,
            task: item.task,
            timeToRelax: item.timeToRelax,
            timeToTraining: item.timeToTraining,
            type: item.type,
            warmUp: item.warmUp,
            warmUpJoint: item.warmUpJoint,
        });
    }

    const handleGoToAddTrain = (event) => {
        event.preventDefault();
        history.push('/addTrain');
    }

    return (
        <Template>
            <div className='training__container'>
                <div className='train__container__head'>
                    <h3 className='training__header'>Тренировки</h3>
                    <button onClick={handleGoToAddTrain} className='exercise__header right add'>Добавить</button>
                </div>
                {   training ?
                    <table className='training__table'>
                        <thead className='training__table__header'>
                        <tr className='training__table__row table-tr__th'>
                            <th>Номер</th>
                            <th>Название тренировки</th>
                            <th>Код</th>
                            <th>Кол-во циклов</th>
                            <th>Кол-во упражнений</th>
                            <th>Пол</th>
                            <th>Уровень тренировки</th>
                            <th>Место тренировки</th>
                            <th>Название секции</th>
                            <th>Цель тренировки</th>
                            <th>Время отдыха</th>
                            <th>Время тренировки</th>
                            <th>Тип тренировки</th>
                        </tr>
                        </thead>
                        <tbody>
                        {training ? training.map((item, index) =>
                            <tr className='training__table__row training__link table-tr__td' key={index}
                                onClick={() => handleClick(item)} >
                                <td>{index + 1}</td>
                                <td>{item.name || '-'}</td>
                                <td>{item.code || '-'}</td>
                                <td>{item.countCircles || '-'}</td>
                                <td>{item.countExercise || '-'}</td>
                                <td>{item.gender || '-'}</td>
                                <td>{item.level || '-'}</td>
                                <td>{item.place || '-'}</td>
                                <td>{item.sectionName || '-'}</td>
                                <td>{item.target || '-'}</td>
                                <td>{item.timeToRelax || '-'}</td>
                                <td>{item.timeToTraining || '-'}</td>
                                <td>{item.type || '-'}</td>
                            </tr>
                        ) : null}
                        </tbody>
                    </table> : <Loader/>
                }
            </div>
        </Template>
    )
}

export default TrainingScreen;