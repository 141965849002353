import Template from "../../Components/Template";
import Loader from "../../Components/Loader";
import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../Components/Provider";
import {useHistory} from "react-router-dom";
import {getCategories} from "../../Models/Categories";
import {getSections} from "../../Models/Sections";

const Categories = () => {

    const [categories, setCategories] = useState([]);
    const {sectionsData, setSectionsData} = useContext(Context);

    const history = useHistory();


    useEffect(() => {
        const getData = async () => {

            try {
                const categoriesResult = await getCategories();
                setCategories(categoriesResult);

                const sectionsResult = await getSections();
                setSectionsData(sectionsResult);

            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);


    const handleClick = (item) => {
        // передаем параметры в объекте вторым аргументом
        history.push('/category', {
            id: item._id,
            name: item.name,
        });
    }

    const handleGoToAddCategory = (event) => {
        event.preventDefault();
        history.push('/addCategory');
    }

    return (
        <Template>
            <div className='exercises__container'>
                <div className='exercise__container__head'>
                    <h3 className='exercises__header'>Категории</h3>
                    <button onClick={handleGoToAddCategory} className='exercise__header right add'>Добавить</button>
                </div>
                {   categories ?
                    <table className='exercises__table'>
                        <thead className='exercises__table__header'>
                        <tr className='exercises__table__row table-tr__th'>
                            <th>№</th>
                            <th>Название</th>
                            <th>Секция</th>
                        </tr>
                        </thead>
                        <tbody>
                        {categories ? categories.map((item, index) =>
                            <tr className='exercises__table__row exercises__link table-tr__td' key={index}
                                onClick={() => handleClick(item)} >
                                <td>{index + 1}</td>
                                <td>{item.name || '-'}</td>
                                <td>
                                    {sectionsData.length > 0 && (
                                        sectionsData.find((sectionItem) => sectionItem._id === item.sectionId)?.name || 'Нет секции'
                                    )}
                                </td>

                            </tr>
                        ) : null}
                        </tbody>
                    </table> : <Loader/>
                }
            </div>
        </Template>)
}

export default Categories;