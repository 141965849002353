import Template from "../../Components/Template";
import {useHistory} from "react-router-dom";
import {useState, useEffect} from "react";
import {create} from "../../Models/Settings";
import React from "react";
import { useForm, Controller } from 'react-hook-form';

import './styles.css';

const AddSetting = () => {

    const { control, handleSubmit } = useForm();

    const history = useHistory();

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/settings');
    }

    const onSubmit = async (data) => {

        const { name, code, ...temp } = data;

        const trueKeys = [];

        for (let key in temp) {
            if (temp.hasOwnProperty(key) && temp[key] === true) {
                trueKeys.push(key);
            }
        }

        const value = {
            "type": "row",
            "content": trueKeys.map(key => ({ "type": key }))
        };

        const jsonValueString = JSON.stringify(value, null);

        const postData = {
            "name" : name,
            "code" : code,
            "value" : jsonValueString,
        }

        // console.log(postData);
        await create(postData);
    };

    function handleClick() {
        alert('Настройка добавлена');
    }

    return (
    <Template>
        <div className='exercise__container__head'>
            <div className='head__edit'>
                <button onClick={handleGoBack} className='back-button'></button>
                <h4 className='exercise__header left'>Создание настройки</h4>
            </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='settings__form-container'>
            <label>
                <span className='exercise__form-container__name mt'>Название</span>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <input className='settings__form-container__input' {...field} />}
                />
            </label>
            <label>
                <span className='exercise__form-container__name mt'>Код</span>
                <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <input className='settings__form-container__input' {...field} />}
                />
            </label>
            <h3 className='settings__checkbox__header'>Тип(-ы) настройки схемы</h3>
            <label className="settings__checkbox-container">
                <Controller
                    name="food"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <input type="checkbox" {...field} className="settings__large-checkbox-input" />
                    )}
                />
                <span className="settings__label-text">Еда</span>
            </label>
            <label className="settings__checkbox-container">
                <Controller
                    name="exercises"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <input type="checkbox" {...field} className="settings__large-checkbox-input" />
                    )}
                />
                <span className="settings__label-text">Упражнения</span>
            </label>
            <label className="settings__checkbox-container">
                <Controller
                    name="complexes"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <input type="checkbox" {...field} className="settings__large-checkbox-input" />
                    )}
                />
                <span className="settings__label-text">Комплекы</span>
            </label>
            <label className="settings__checkbox-container">
                <Controller
                    name="schedules"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <input type="checkbox" {...field} className="settings__large-checkbox-input" />
                    )}
                />
                <span className="settings__label-text">Графики</span>
            </label>
            <label className="settings__checkbox-container">
                <Controller
                    name="trainings"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <input type="checkbox" {...field} className="settings__large-checkbox-input" />
                    )}
                />
                <span className="settings__label-text">Тренировки</span>
            </label>
            <p className="settings__checkbox__description">Выберите один или несколько типов из представленных для создания схемы для пользователя</p>
            <div className='settings__form-container__button-container'>
                <button onClick={handleClick} className='settings__form-container__button' type="submit">Сохранить</button>
            </div>
        </form>
    </Template>
    );
}

export default AddSetting;