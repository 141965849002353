import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {ls} from "../../Globals/Localstorage";
import Template from "../../Components/Template";
import {get} from "../../Models/Settings"
import Loader from "../../Components/Loader";

const SettingsScreen = () => {

    const [settings, setSettings] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get();
                setSettings(response);
            } catch (error) {
                setError(error);
            }
        };

        fetchData();
    }, []);


    const handleClick = (item) => {
        // передаем параметры в объекте вторым аргументом
        history.push('/setting', {
            name: item.name,
            code: item.code,
            value: item.value,
        });
    }

    const handleGoToAddSetting = (event) => {
        event.preventDefault();
        history.push('/addSetting');
    }

    return (
        <Template>
            <div className='exercises__container'>
                <div className='exercise__container__head'>
                    <h3 className='exercises__header'>Настройки</h3>
                    <button onClick={handleGoToAddSetting} className='exercise__header right add'>Добавить</button>
                </div>
                {   settings ?
                    <table className='exercises__table'>
                        <thead className='exercises__table__header'>
                            <tr className='exercises__table__row table-tr__th'>
                                <th>№</th>
                                <th>Код</th>
                                <th>Название</th>
                                {/*<th>Значение</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                        {settings ? settings.map((item, index) =>
                            <tr className='exercises__table__row exercises__link table-tr__td' key={index}
                                onClick={() => handleClick(item)} >
                                <td>{index + 1}</td>
                                <td>{item.code || '-'}</td>
                                <td>{item.name || '-'}</td>
                                {/*<td>{item.value || '-'}</td>*/}
                            </tr>
                        ) : null}
                        </tbody>
                    </table> : <Loader/>
                }
            </div>
        </Template>
    )
}

export default SettingsScreen;