import Template from "../../Components/Template";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {getItems} from "../../Models/Items";
import Loader from "../../Components/Loader";
import {Context} from "../../Components/Provider";
import {getCategories} from "../../Models/Categories";

const Items = () => {

    const [items, setItems] = useState([]);
    const {categoriesData, setCategoriesData} = useContext(Context);

    const history = useHistory();

    useEffect(() => {
        const getData = async () => {

            try {
                const itemsResult = await getItems();
                setItems(itemsResult);

                const categoriesResult = await getCategories();
                setCategoriesData(categoriesResult);

            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        console.log(items);
    })

    const handleClick = (item) => {
        // передаем параметры в объекте вторым аргументом
        history.push('/item', {
            id: item._id,
            name: item.name,
            description: item.description,
            images: item.images,
            price: item.price,
            categoryId: item.categoryId,
            article: item.article,
            color: item.color,
            size: item.size,
            characters: item.characters,
        });
    }

    const handleGoToAddSection = (event) => {
        event.preventDefault();
        history.push('/addItem');
    }

    return (
        <Template>
            {   items.length > 0 ?
                <div className='exercises__container'>
                    <div className='exercise__container__head'>
                        <h3 className='exercises__header'>Предметы</h3>
                        <button onClick={handleGoToAddSection} className='exercise__header right add'>Добавить</button>
                    </div>
                    {items ?
                        <table className='exercises__table'>
                            <thead className='exercises__table__header'>
                            <tr className='exercises__table__row table-tr__th'>
                                <th>№</th>
                                <th>Название</th>
                                <th>Артикул</th>
                                <th>Цена</th>
                                <th>Цвет</th>
                                <th>Размер(-ы)</th>
                                {/*<th>Характеристики</th>*/}
                                <th>Категория</th>
                            </tr>
                            </thead>
                            <tbody>
                            {items.length > 0 ? items.map((item, index) =>
                                <tr className='exercises__table__row exercises__link table-tr__td' key={index}
                                    onClick={() => handleClick(item)}>
                                    <td>{index + 1}</td>
                                    <td>{item.name || '-'}</td>
                                    <td>{item.article || '-'}</td>
                                    <td>{item.price || '-'}</td>
                                    <td>{item.color || '-'}</td>
                                    <td>
                                        {typeof item.size === "string"
                                            ? item.size
                                            : Array.isArray(item.size)
                                                ? item.size.map((sizeItem, index) => (
                                                    <div key={index}>{index + 1}. {sizeItem}</div>
                                                ))
                                                : null}
                                    </td>
                                    {/*<td>*/}
                                    {/*    {item.characters && Object.keys(item.characters).map((key) => (*/}
                                    {/*        <div key={key}>{`${key} - ${item.characters[key]}`}</div>*/}
                                    {/*    ))}*/}
                                    {/*</td>*/}
                                    <td>
                                        {categoriesData.length > 0 && (
                                            categoriesData.find((categoriesItem) => categoriesItem._id === item.categoryId)?.name || 'Нет категории'
                                        )}
                                    </td>
                                </tr>
                            ) : null}
                            </tbody>
                        </table> : <Loader/>
                    }
                </div> : <Loader/>
            }
        </Template>)
}

export default Items;