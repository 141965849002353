import Http from '../../Globals/Http';

const get		= async ()						=> await Http.get('admin/exercises');

const update	= async (id, data)				=> await Http.put(`admin/exercises/${id}`, data);
const create	= async (data)				=> await Http.request(`admin/exercises`, data);

export {
    get,
    update,
    create
}