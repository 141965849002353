const { load } = require('js-yaml');

const commonStatus = {
    IN_ACTIVE: 0,
    ACTIVE: 1
};
const commonStatusName = ['Не активен', 'Активен'];

const userStatus = {
    ACTIVE: 1,
    IN_ACTIVE: 2,
    BAN: 3,
    DELETED: 4
};
const userStatusName = ['', 'Активен', 'Не активен', 'Заблокирован', 'Удален'];

const sectionType = {
    НЕИЗВЕСТНО: 0,
    ТАКСИ: 1,
    ЕДА: 2,
    ОТЕЛИ: 3,
    МАРКЕТ: 4,
    ДОСТАВКА: 5,
    АВИА: 6,
    БРОНИРОВАНИЕ: 7
};

const API = {
    key: '516a640f-2853-4824-bf42-c85786d938ee',
    pushKey: '134498f8-eddd-4614-a632-f5fe93be0cce',
    version: '1.0.0',
    url : '',
}

var data;
var urlss;

function getConfig() {
    return fetch('/config.yml')
        .then(response => response.text())
        .then(yamlData => {
            data = load(yamlData);
            urlss = data;
        })
        .catch(error => console.error(error));
}

getConfig().then(() => {
    API.url = urlss.urls.api.fitness;
    API.marketUrl = urlss.urls.api.fitnessMarketplace;
    // API.url = 'http://localhost:3000/api/v1/';
    // Ваш код, который использует значение API.url
    // console.log(API.url);
});

export {
    API,
    commonStatus,
    commonStatusName,
    userStatus,
    userStatusName,
    sectionType,
};