import React, {useEffect, useState} from 'react';
import Template from "../../Components/Template";
import {get} from "../../Models/Dish";

const FoodScreen = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await get();
                setData(result);
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    console.log(data);

    return (
        <Template>
            <div>Food</div>
        </Template>
    )
}

export default FoodScreen;