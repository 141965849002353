import Template from "../../Components/Template";
import React, {useState} from "react";
import {update} from "../../Models/Settings";
import {useHistory, useLocation} from "react-router-dom";

const Setting = () => {

    const history = useHistory();
    const location = useLocation();

    const [formData, setFormData] = useState({
        name: location.state.name || '',
        code: location.state.code || '',
        value: location.state.value || '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const updateMessage = {
            "name": formData.name,
            "value": formData.value,
        };

        await update(location.state.code, updateMessage);
    }

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/settings');
    }

    function handleClick() {
        alert('Сохранено');
    }

    return (
      <Template>
          <div className='exercise__container__head'>
              <div className='head__edit'>
                  <button onClick={handleGoBack} className='back-button'></button>
                  <h4 className='exercise__header left'>Редактирование настройки</h4>
              </div>
          </div>
          <form className='exercise__form-container' onSubmit={handleSubmit}>
              <div className='exercise__form-container__grid'>
                  <label>
                      <span className='exercise__form-container__name'>Название</span>
                      <input className='exercise__form-container__input' type="text" name="name" value={formData.name} onChange={handleInputChange} />
                  </label>
                  {/*<label>*/}
                  {/*    <span className='exercise__form-container__name'>Код</span>*/}
                  {/*    <input className='exercise__form-container__input' type="text" name="code" value={formData.code} onChange={handleInputChange} />*/}
                  {/*</label>*/}
                  {/*<label>*/}
                  {/*    <span className='exercise__form-container__name'>Значение</span>*/}
                  {/*    <input className='exercise__form-container__input' type="text" name="value" value={formData.value} onChange={handleInputChange} />*/}
                  {/*</label>*/}
              </div>
              <div className='exercise__form-container__button-container'>
                <button onClick={handleClick} className='exercise__form-container__button ' type="submit">Сохранить</button>
              </div>
          </form>
      </Template>
    );
}

export default Setting;