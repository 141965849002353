import Template from "../../Components/Template";
import React, {useEffect} from "react";
import {update} from "../../Models/Items";
import {useHistory, useLocation} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";

// styles
import './styles.css'

const Item = () => {

    const imageUrl = 'https://s3.super-appz.ru/download/marketplace/products/naprolom';
    const history = useHistory();
    const location = useLocation();
    const oldName = location.state.name;
    const name = oldName.replace(/й/g, 'й');
    const encodedName = encodeURI(name);


    const { control, handleSubmit } = useForm();

    const onSubmit = async (data) => {

        const { name, description, images, price, article, color, size} = data;

        const characters = { ...location.state.characters, ...data};

        const updateData = {
            "name" : name,
            "description" : description,
            // "images" : images,
            "price" : price,
            "categoryId": location.state.categoryId,
            "article": article,
            "color": color,
            "size": size,
            "characters": characters,
        }

        // console.log(updateData);
        await update(location.state.id, updateData);
    };

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/items');
    }

    function handleClick() {
        alert('Предмет изменён');
    }

    return (
        <Template>
            <div className='exercise__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='exercise__header left'>Редактирование предмета</h4>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='settings__form-container'>
                <div className='item__main__container'>
                    <label>
                        <span className='exercise__form-container__name mt'>Название</span>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={location.state.name}
                            render={({ field }) => <input className='settings__form-container__input' {...field} />}
                        />
                    </label>
                    <label>
                        <span className='exercise__form-container__name mt'>Описание</span>
                        <Controller
                            name="description"
                            control={control}
                            defaultValue={location.state.description}
                            render={({ field }) => <textarea className='settings__form-container__input' {...field} />}
                        />
                    </label>
                    <label>
                        <span className='exercise__form-container__name mt'>Цена</span>
                        <Controller
                            name="price"
                            control={control}
                            defaultValue={location.state.price}
                            render={({ field }) => <input className='settings__form-container__input' {...field} />}
                        />
                    </label>
                    <label>
                        <span className='exercise__form-container__name mt'>Артикул</span>
                        <Controller
                            name="article"
                            control={control}
                            defaultValue={location.state.article}
                            render={({ field }) => <input className='settings__form-container__input' {...field} />}
                        />
                    </label>
                    <label>
                        <span className='exercise__form-container__name mt'>Цвет</span>
                        <Controller
                            name="color"
                            control={control}
                            defaultValue={location.state.color}
                            render={({ field }) => <input className='settings__form-container__input' {...field} />}
                        />
                    </label>
                    <label>
                        <span className='exercise__form-container__name mt'>Размер(-ы)</span>
                        <Controller
                            name="size"
                            control={control}
                            defaultValue={location.state.size}
                            render={({ field }) => <input className='settings__form-container__input' {...field} />}
                        />
                    </label>
                    {   location.state.characters &&
                        <label>
                            <span className='item__difficult__elements__header'>Характеристики</span>
                            <div className='item__characters__container'>
                                {Object.entries(location.state.characters).length > 0 ?
                                    Object.entries(location.state.characters).map(([key, value], index) => (
                                        <div key={index} className='item__characters__block'>
                                            <span className='exercise__form-container__name mt'>{key}</span>
                                            <Controller
                                                name={key}
                                                control={control}
                                                defaultValue={value}
                                                render={({field}) => <input
                                                    className='settings__form-container__input' {...field} />}
                                            />
                                        </div>
                                    )) : null
                                }
                            </div>
                        </label>
                    }
                    <label>
                        <span className='item__difficult__elements__header mb'>Изображение(-я)</span>
                        <div className='item__images__container'>
                            {location.state.images.map((imageItem, imageIndex) => {
                                const newImageItem = imageItem.replace(/й/g, 'й');
                                const encodedImageItem = encodeURI(newImageItem);

                                return (
                                    <div key={imageIndex} className='item__image__wrapper'>
                                        <img
                                            src={`${imageUrl}/${encodedName}/${encodedImageItem}`}
                                            alt={imageItem}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </label>
                </div>
                <div className='settings__form-container__button-container'>
                    <button onClick={handleClick} className='item__form-container__button' type="submit">Сохранить</button>
                </div>
            </form>
        </Template>
    );
}

export default Item;