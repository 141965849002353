import HttpMarket from "../../Globals/HttpMarket";

const getSections			= async ()			=> await HttpMarket.get('admin/sections');

const create			    = async (data)		=> await HttpMarket.request('admin/sections', data);

const update		        = async (id, data)	=> await HttpMarket.put(`admin/sections/${id}`, data);


export {
    getSections,
    create,
    update
}