import Template from "../../Components/Template";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {get} from "../../Models/Orders";
import Loader from "../../Components/Loader";
import {getItems} from "../../Models/Items";
import {getUserById, getUsers} from "../../Models/Users";

const Orders = () => {

    const [orders, setOrders] = useState([]);
    const [itemsData, setItemsData] = useState([]);
    const [usersName, setUsersName] = useState([]);
    // const [usersData, setUsersData] = useState([]);

    const history = useHistory();

    useEffect(() => {
        const getData = async () => {

            try {
                const ordersResult = await get();
                setOrders(ordersResult);

                const itemsResult = await getItems();
                setItemsData(itemsResult);

                // const usersResult = await getUsers();
                // setUsersData(usersResult);

            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    // const getCurrentUsersOrder = async (userId) => {
    //     try {
    //         const user = await getUserById(userId);
    //         return user.name;
    //     } catch (error) {
    //         console.error(error);
    //         return 'Ошибка при получении имени пользователя';
    //     }
    // }

    const getCurrentUsersOrder = async (userId) => {
        try {
            const user = await getUserById(userId);
            return user.name;
        } catch (error) {
            console.error(error);
            return 'Ошибка при получении имени пользователя';
        }
    }

    const getUserNamesForOrders = async (orders) => {
        const userNamesPromises = orders.map(async (item) => {
            try {
                const userName = await getCurrentUsersOrder(item.userId);
                return userName;
            } catch (error) {
                console.error(error);
                return 'Ошибка при получении имени пользователя';
            }
        });

        return Promise.all(userNamesPromises);
    }

    useEffect(() => {
        const fetchData = async () => {
            const userNames = await getUserNamesForOrders(orders);
            setUsersName(userNames);
        };

        fetchData(); // Вызывайте fetchData сразу при монтировании компонента

    }, [orders]);

    return (
        <Template>
            <div className='exercises__container'>
                <div className='exercise__container__head'>
                    <h3 className='exercises__header'>Заказы</h3>
                </div>
                {   orders ?
                    <table className='exercises__table'>
                        <thead className='exercises__table__header'>
                        <tr className='exercises__table__row table-tr__th'>
                            <th>№</th>
                            <th>Имя</th>
                            <th>Фамилия</th>
                            <th>Телефон</th>
                            <th>Предметы</th>
                            <th>Адрес</th>
                            <th>Пользователь</th>
                            <th>Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orders && orders.map((item, index) =>
                            <tr className='exercises__table__row exercises__link table-tr__td' key={index}>
                                <td>{index + 1}</td>
                                <td>{item.firstName || '-'}</td>
                                <td>{item.secondName || '-'}</td>
                                <td>{item.phone || '-'}</td>
                                <td>{
                                    item.items.map((item, index) => {
                                        const foundItem = itemsData.find((itemsElement) => itemsElement._id === item.itemId);
                                        const itemName = foundItem ? foundItem.name : 'Нет такого предмета';

                                        return (
                                            <div key={index}>
                                                {index + 1}. {itemName} - {item.quantity} шт.
                                            </div>
                                        );
                                    })
                                }</td>
                                <td>{item.address}</td>
                                <td>{usersName[index]}</td>
                                <td>{item.status}</td>
                            </tr>
                        )}
                        </tbody>
                    </table> : <Loader/>
                }
            </div>
        </Template>)
}

export default Orders;