import Template from "../../Components/Template";
import React from "react";
import {update} from "../../Models/Sections";
import {useHistory, useLocation} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";

const Section = () => {

    const history = useHistory();
    const location = useLocation();

    const { control, handleSubmit } = useForm();

    const onSubmit = async (data) => {

        const { name } = data;

        const updateData = {
            "name" : name,
        }

        // console.log(updateData);
        await update(location.state.id, updateData);
    };

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/sections');
    }

    function handleClick() {
        alert('Секция изменена');
    }

    return (
        <Template>
            <div className='exercise__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='exercise__header left'>Редактирование секции</h4>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='settings__form-container'>
                <label>
                    <span className='exercise__form-container__name mt'>Название</span>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={location.state.name}
                        render={({ field }) => <input className='settings__form-container__input' {...field} />}
                    />
                </label>
                <div className='settings__form-container__button-container'>
                    <button onClick={handleClick} className='settings__form-container__button' type="submit">Сохранить</button>
                </div>
            </form>
        </Template>
    );
}

export default Section;