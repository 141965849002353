import Template from "../../Components/Template";
import {useHistory} from "react-router-dom";
import {useState, useEffect, useContext} from "react";
import {create} from "../../Models/Categories";
import React from "react";
import { useForm, Controller } from 'react-hook-form';
import {Context} from "../../Components/Provider";

import '../Categories/styles.css'
import {options} from "axios";

const AddCategory = () => {

    const {sectionsData} = useContext(Context);
    const { control, handleSubmit } = useForm();

    const history = useHistory();

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/categories');
    }

    const onSubmit = async (data) => {

        const { name, sectionName } = data;

        let sectionId = '';

        if (sectionsData.length > 0) {
            sectionId = sectionsData.find((sectionItem) => sectionItem.name === sectionName)?._id;
        }

        const postData = {
            "name" : name,
            "sectionId" : sectionId,
        }

        // console.log(postData);
        await create(postData);
    };

    function handleClick() {
        alert('Категория добавлена');
    }

    useEffect(() => {
        console.log(sectionsData)
    }, [sectionsData]);

    return (
        <Template>
            <div className='exercise__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='exercise__header left'>Создание категории</h4>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='settings__form-container'>
                <label>
                    <span className='exercise__form-container__name mt'>Название</span>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input className='settings__form-container__input' {...field} />}
                    />
                </label>
                <label>
                    <label htmlFor="sectionName" className='exercise__form-container__name mt'>Выберите секцию</label>
                    <Controller
                        name="sectionName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <select {...field} className='categories__form-container__select'>
                                <option value="" disabled> </option>
                                {sectionsData.length > 0 && sectionsData.map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                            </select>
                        )}
                    />
                </label>
                <div className='settings__form-container__button-container'>
                    <button onClick={handleClick} className='settings__form-container__button' type="submit">Сохранить</button>
                </div>
            </form>
        </Template>
    );
}

export default AddCategory;