import React, {useContext, useEffect, useState} from 'react';
import { useLocation, useHistory } from "react-router-dom";

// styles
import './styles.css';

import Template from "../../Components/Template";
import Loader from "../../Components/Loader";
import {get, update} from "../../Models/Exercise";
// import { LRUCache } from 'lru-cache';
import {Context} from "../../Components/Provider";
// const cache = new LRUCache({ max: 100 });

const ExerciseScreen = () => {

    const {setExercise, setCash} = useContext(Context);
    const imgUrl = 'https://s3.super-appz.ru/download/fitness/images/exercises';
    const videoUrl = 'https://s3.super-appz.ru/download/fitness/video/exercises';
    const location = useLocation();
    const history = useHistory();

    const [formData, setFormData] = useState({
        id: location.state.id,
        code: location.state?.code || '',
        forChildren: location.state?.forChildren || false,
        gender: location.state?.gender || '',
        important: location.state?.important || '',
        inventory: location.state?.inventory || '',
        muscles: location.state?.muscles || '',
        name: location.state?.name || '',
        replace: location.state?.replace?.join(', ') || '',
        replaceForSick: location.state?.replaceForSick?.join(', ') || '',
        sectionName: location.state?.sectionName || '',
        startingPosition : location.state?.startingPosition || '',
        technique : location.state?.technique,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputChangeBoolean = (event) => {
        const { name, value } = event.target;
        const newValue = value === 'true';
        setFormData({
            ...formData,
            [name]: newValue
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const updateMessage = {
            "code": formData.code,
            "forChildren": formData.forChildren,
            "gender" : formData.gender,
            "important": formData.important,
            "inventory": formData.inventory,
            "muscles": formData.muscles,
            "name": formData.name,
            "replace": formData.replace,
            "replaceForSick": formData.replaceForSick,
            "sectionName": formData.sectionName,
            "startingPosition" : formData.startingPosition,
            "technique" : formData.technique,
        };


        await update(location.state.id, updateMessage);
        // cache.delete('exercises');
        const result = await get();
        // cache.set('exercises', result);
        setExercise(result);
        setCash(result);
    };

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/exercises');
    }



    function handleClick() {
        alert('Сохранено');
    }

    return (
        <Template>
            <div className='exercise__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='exercise__header left'>Редактирование упражнения</h4>
                </div>
                {/*<h4 className='exercise__header right'>Фотографии / Видео упражнения</h4>*/}
            </div>
            <form className='exercise__form-container' onSubmit={handleSubmit}>
                <div className='exercise__form-container__grid'>
                    <label>
                        <span className='exercise__form-container__name'>Код</span>
                        <input className='exercise__form-container__input' type="text" name="code" value={formData.code} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Для детей</span>
                        <select className='exercise__form-container__input' type="boolean" name="forChildren" value={formData.forChildren} onChange={handleInputChangeBoolean}>
                            <option value={false}>Нет</option>
                            <option value={true}>Да</option>
                        </select>
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Пол</span>
                        <input className='exercise__form-container__input' type="text" name="gender" value={formData.gender} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Важно</span>
                        <textarea className='exercise__form-container__input' type="text" name="important" value={formData.important} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Инвентарь</span>
                        <input className='exercise__form-container__input' type="text" name="inventory" value={formData.inventory} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Мышцы</span>
                        <textarea className='exercise__form-container__input' type="text" name="muscles" value={formData.muscles} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Название</span>
                        <textarea className='exercise__form-container__input' type="text" name="name" value={formData.name} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Похожее упражнение</span>
                        <input className='exercise__form-container__input' type="text" name="replace" value={formData.replace} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Похожее упражнение при болезни</span>
                        <input className='exercise__form-container__input' type="text" name="replaceForSick" value={formData.replaceForSick} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Название секции</span>
                        <textarea className='exercise__form-container__input' type="text" name="sectionName" value={formData.sectionName} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Исходная позиция</span>
                        <textarea className='exercise__form-container__input' type="text" name="startingPosition" value={formData.startingPosition} onChange={handleInputChange} />
                    </label>
                    <label>
                        <span className='exercise__form-container__name'>Техника</span>
                        <textarea className='exercise__form-container__input' type="text" name="technique" value={formData.technique} onChange={handleInputChange} />
                    </label>
                </div>
                <div className='exercises__form-container__img'>
                    <img className='exercises__form-container__img__item one' src={`${imgUrl}/${formData.code}/${formData.code}-1.jpg`} onError={(e) => e.target.remove()} alt=""/>
                    <img className='exercises__form-container__img__item two' src={`${imgUrl}/${formData.code}/${formData.code}-2.jpg`} onError={(e) => e.target.remove()} alt=""/>
                    <img className='exercises__form-container__img__item three' src={`${imgUrl}/${formData.code}/${formData.code}-3.jpg`} onError={(e) => e.target.remove()} alt=""/>
                    <img className='exercises__form-container__img__item four' src={`${imgUrl}/${formData.code}/${formData.code}-4.jpg`} onError={(e) => e.target.remove()} alt=""/>
                    <img className='exercises__form-container__img__item five' src={`${imgUrl}/${formData.code}/${formData.code}-5.jpg`} onError={(e) => e.target.remove()} alt=""/>
                    <img className='exercises__form-container__img__item six' src={`${imgUrl}/${formData.code}/${formData.code}-6.jpg`} onError={(e) => e.target.remove()} alt=""/>
                    <div className='exercise__video'>
                        <video src={`${videoUrl}/${formData.code}/${formData.code}.mp4`} controls width="640" height="360">
                            Ваш браузер не поддерживает воспроизведение видео.
                        </video>
                    </div>
                </div>
                <div className='exercise__form-container__button-container'>
                    <button onClick={handleClick} className='exercise__form-container__button ' type="submit">Сохранить</button>
                </div>
            </form>
        </Template>
    );
};

export default ExerciseScreen;