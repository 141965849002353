import Template from "../../Components/Template";
import {useHistory} from "react-router-dom";
import {create} from "../../Models/Sections";
import React from "react";
import { useForm, Controller } from 'react-hook-form';

const AddSection = () => {

    const { control, handleSubmit } = useForm();

    const history = useHistory();

    const handleGoBack = (event) => {
        event.preventDefault();
        history.push('/sections');
    }

    const onSubmit = async (data) => {

        const { name } = data;

        const postData = {
            "name" : name,
        }

        // console.log(postData);
        await create(postData);
    };

    function handleClick() {
        alert('Секция добавлена');
    }

    return (
        <Template>
            <div className='exercise__container__head'>
                <div className='head__edit'>
                    <button onClick={handleGoBack} className='back-button'></button>
                    <h4 className='exercise__header left'>Создание секции</h4>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='settings__form-container'>
                <label>
                    <span className='exercise__form-container__name mt'>Название</span>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input className='settings__form-container__input' {...field} />}
                    />
                </label>
                <div className='settings__form-container__button-container'>
                    <button onClick={handleClick} className='settings__form-container__button' type="submit">Сохранить</button>
                </div>
            </form>
        </Template>
    );
}

export default AddSection;