import HttpMarket from "../../Globals/HttpMarket";

const getCategories			= async ()			=> await HttpMarket.get('admin/categories');

const create			    = async (data)		=> await HttpMarket.request('admin/categories', data);

const update		        = async (id, data)	=> await HttpMarket.put(`admin/categories/${id}`, data);


export {
    getCategories,
    create,
    update
}